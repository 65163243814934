<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack()"> สต๊อกสินค้า </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ stockShop.name }}
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ข้อมูลสินค้า -->
    <v-card elevation="1">
      <v-card-title>
        <v-icon size="22" color="orange darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">ข้อมูลสินค้า</span>
        <v-spacer />
        <v-btn :elevation="1" color="primary" class="pa-2" dark outlined @click="UpdateStock()">
          <v-icon size="16" class="pr-1">fa-edit</v-icon>
          แก้ไขสินค้า
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>

          <v-col cols="12">
            <div class="px-0 v-list-item">

              <a v-if="stockShop.photoLink != null && stockShop.photoLink != ''"
                @click="OpenPhoto(stockShop.photoLink)">
                <div style="width:100px">
                  <img :src="stockShop.photoLink" style="width:100%">
                </div>
              </a>
              <div v-if="stockShop.photoLink == null || stockShop.photoLink == ''" style="width:100px">
                <img src='https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Nophoto.jpg' style="width:100%">
              </div>

              <div class="v-list-item__content ml-4">
                <div class="v-list-item__title subtitle-1 packhai-showproduct">
                  <div>
                    <div>
                      ชื่อสินค้า
                    </div>
                    <div>
                      : {{ stockShop.name }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="v-markdown subtitle-1 packhai-showproduct">
                    <div>
                      <div>
                        ลักษณะ
                      </div>
                      <div>
                        : {{ (stockShop.prop1Description != null && stockShop.prop1Description != "" ?
                          stockShop.prop1Description : "") + (stockShop.prop2Description != null &&
                            stockShop.prop2Description != "" ? " , " + stockShop.prop2Description : "") }}
                      </div>
                    </div>

                    <br />
                    <div>
                      <div>
                        SKU
                      </div>
                      <div>
                        : {{ stockShop.sku }}
                      </div>
                    </div>

                    <br />
                    <div>
                      <div>
                        Barcode
                      </div>
                      <div>
                        : {{ stockShop.barcode }}
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-8">
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 v-if="stockShop.quantity < 0" style="color:red">
              {{ formatMoney(stockShop.quantity) }}
            </h1>
            <h1 v-if="stockShop.quantity >= 0" style="color:Green">
              {{ formatMoney(stockShop.quantity) }}
            </h1><br>
            <span>
              คงเหลือ

            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 style="color:red">
              {{ formatMoney(stockShop.quantityWaiting) }}
            </h1><br>
            <span>
              รอแพ็ค
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 style="color:Orange">
              {{ formatMoney(stockShop.quantityWaitImport) }}
            </h1><br>
            <span>
              รอนำเข้า

            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 v-if="stockShop.quantityAvailable < 0" style="color:red">
              {{ formatMoney(stockShop.quantityAvailable) }}
            </h1>
            <h1 v-if="stockShop.quantityAvailable >= 0" style="color:Green">
              {{ formatMoney(stockShop.quantityAvailable) }}
            </h1><br>
            <span>
              <span color="success">พร้อมขาย</span>

            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- ข้อมูลสต๊อก -->
    <v-card elevation="1" class="mt-8">
      <v-card-title>
        <v-icon size="22" color="red darken-3">mdi mdi-file-document</v-icon><span class="ml-3">ข้อมูลสต๊อก</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text>

        <v-tabs v-model="tab">
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab v-for="i in tab_list" :key="i.id" @click="SeeDetails(i.id, false)"> {{ i.name }}
            ( {{ i.id == 1 ? (dataset1.length > 0 ? dataset1.length - 1 : 0) : (i.id == 2) ? dataset2.length : (i.id ==
              3) ?
              (waitingPackBody.dataset.length > 0 ? waitingPackBody.dataset.length - 1 : 0) :
              (i.id == 4) ? (exportWaitingBody.dataset.length > 0 ? exportWaitingBody.dataset.length - 1 : 0) : (i.id ==
                5) ?
                (importWaitingBody.dataset.length > 0 ? importWaitingBody.dataset.length - 1 : 0) : 0 }} )</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="i in tab_list" :key="i.id">
            <v-card flat>
              <v-card-text>

                <div v-if="i.id == 1">
                  <v-row>
                    <v-col cols="12" md="2" lg="1" class="py-2">
                      <h3 class="fn-14"> วันที่ : </h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2" class="py-2">
                      <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="290px" max-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateStart" outlined readonly label="เริ่มต้น"
                            prepend-inner-icon="mdi-calendar" @blur="startDate = parseDate(dateStart)" v-bind="attrs"
                            v-on="on" hide-details clearable @click:clear="dateStart = null"
                            style="width: 290px;"></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="menuStartDate = false"
                          @change="dateStart = formatDate(startDate), CheckDateTime(i.id, startDate, endDate)" no-title
                          :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2" class="py-2">
                      <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="290px" max-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateEnd" outlined readonly label="สิ้นสุด"
                            prepend-inner-icon="mdi-calendar" @blur="endDate = parseDate(dateEnd)" v-bind="attrs"
                            v-on="on" hide-details clearable @click:clear="dateEnd = null"
                            style="width: 290px;"></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="menuEndDate = false"
                          @change="dateEnd = formatDate(endDate), CheckDateTime(i.id, startDate, endDate)" no-title
                          :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" lg="7" class="py-0" align="right">
                      <vue-excel-xlsx id="GetExcelStockShopItem" v-show="false" :data="dataset1"
                        :columns="StockShopItemHeaderExcel" :file-name="ExcelStockShopFileName"
                        :sheet-name="'Lot นำเข้า'">
                      </vue-excel-xlsx>
                      <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1"
                        @click="ExportExcel('GetExcelStockShopItem')">
                        <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                        <span class="fn-12"> Export Excel </span>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="pb-1">
                    <v-col cols="12" md="2" lg="1" class="py-2">
                      <h3 class="fn-14"> โชว์สินค้า : </h3>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" class="py-2">
                      <v-select v-model="search.showProduct" :items="search.showProductList" item-text="name"
                        item-value="id" persistent-hint return-object dense hide-details outlined
                        single-line></v-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="7" class="py-2">
                      <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStockShopItem()">
                        <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                        ค้นหา
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table :headers="header1" :items="dataset1" :items-per-page="9999999999" hide-default-footer
                    class="packhai-border-table hidden-xs-only">
                    <template v-slot:item.createdDatetime="{ item }">
                      <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                        {{ item.createdDatetime }}
                      </span>
                      <span v-else>
                        {{ item.createdDatetime }}
                      </span>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                        {{ formatMoney(item.quantity) }}
                      </span>
                      <span v-else>
                        {{ formatMoney(item.quantity) }}
                      </span>
                    </template>
                    <template v-slot:item.quantityLeft="{ item }">
                      <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                        {{ formatMoney(item.quantityLeft) }}
                      </span>
                      <span v-else>
                        {{ formatMoney(item.quantityLeft) }}
                      </span>
                    </template>

                    //checkStocksequence > 0 หรือไม่
                    <template v-slot:item.stockSequence="{ item }">
                      <span v-if="item.stockSequence > 0">
                        {{ formatMoney(item.stockSequence) }}
                      </span>


                    </template>

                    <template v-slot:item.importExportMasterId="{ item }">
                      <a @click="NewPage(item.importExportMasterId)"> {{ item.importExportMasterId }} </a>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon v-if="dataset1.length - 1 != dataset1.indexOf(item)" small class="mr-2"
                        @click="editItem(item, 'EditStockShopItem')"> mdi-pencil </v-icon>
                    </template>
                    <template v-slot:item.add="{ item }">
                      <a v-if="dataset1.length - 1 != dataset1.indexOf(item)"
                        @click="editItem(item, 'SplitStockShopItem')">
                        เพิ่ม
                      </a>
                    </template>
                    <template v-slot:item.print="{ item }">
                      <v-icon v-if="dataset1.length - 1 != dataset1.indexOf(item)" class="mr-2"
                        @click="ClickToPrint(item)"> mdi-printer </v-icon>
                    </template>
                  </v-data-table>

                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator :items="dataset1" :items-per-page="9999999999" hide-default-header
                    hide-default-footer class="hidden-sm-and-up">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1">

                              <a @click="NewPage(item.importExportMasterId)"> {{ item.importExportMasterId }} </a>
                              <v-spacer />
                              <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                                {{ item.createdDatetime }}
                              </span>
                              <span v-else>
                                {{ item.createdDatetime }}
                              </span>
                              <v-icon v-if="dataset1.length - 1 != dataset1.indexOf(item)" small class="mr-2"
                                @click="editItem(item, 'EditStockShopItem')"> mdi-pencil </v-icon>
                            </v-row>
                            <v-divider />
                            <v-row v-if="dataset1.length - 1 != dataset1.indexOf(item)" class="ma-0 px-2 py-1 ">
                              เพิ่ม Location
                              <v-spacer />
                              <a v-if="dataset1.length - 1 != dataset1.indexOf(item)"
                                @click="editItem(item, 'SplitStockShopItem')">
                                เพิ่ม
                              </a>
                            </v-row>lotName
                            <v-row v-if="dataset1.length - 1 != dataset1.indexOf(item)" class="ma-0 px-2 py-1 ">
                              LotName
                              <v-spacer />
                              <span>{{ item.LotName }}</span>
                            </v-row>

                            <v-row class="ma-0 px-2 py-1">
                              จำนวนที่รับเข้า
                              <v-spacer />
                              <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                                {{ formatMoney(item.quantity) }}
                              </span>
                              <span v-else>
                                {{ formatMoney(item.quantity) }}
                              </span>
                            </v-row>
                            <v-row class="ma-0 px-2 py-1">
                              จำนวนที่เหลือ
                              <v-spacer />
                              <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                                {{ formatMoney(item.quantityLeft) }}
                              </span>
                              <span v-else>
                                {{ formatMoney(item.quantityLeft) }}
                              </span>
                            </v-row>
                            <v-row class="ma-0 px-2 py-1">
                              ราคาทุน
                              <v-spacer />
                              <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                                {{ formatMoney(item.costPrice) }}
                              </span>
                              <span v-else>
                                {{ formatMoney(item.costPrice) }}
                              </span>
                            </v-row>
                            <v-row class="ma-0 px-2 py-1">
                              มูลค่าคงเหลือ
                              <v-spacer />
                              <span v-if="dataset1.length - 1 == dataset1.indexOf(item)" class="fw-b">
                                {{ formatMoney(item.totalPrice) }}
                              </span>
                              <span v-else>
                                {{ formatMoney(item.totalPrice) }}
                              </span>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>

                <div v-else-if="i.id == 2">
                  <v-row class="pb-1">
                    <v-col cols="12" md="2" lg="1" class="py-2">
                      <h3 class="fn-14"> วันที่ : </h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2" class="py-2">
                      <v-menu ref="menuStartDate2" v-model="menuStartDate2" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="290px" max-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateStart2" outlined readonly label="เริ่มต้น"
                            prepend-inner-icon="mdi-calendar" @blur="startDate2 = parseDate(dateStart2)" v-bind="attrs"
                            v-on="on" hide-details @click:clear="dateStart2 = null"
                            style="width: 290px;"></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate2" @input="menuStartDate2 = false"
                          @change="dateStart2 = formatDate(startDate2), CheckDateTime(i.id, startDate2, endDate2)"
                          no-title :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2" class="py-2">
                      <v-menu ref="menuEndDate2" v-model="menuEndDate2" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="290px" max-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateEnd2" outlined readonly label="สิ้นสุด"
                            prepend-inner-icon="mdi-calendar" @blur="endDate2 = parseDate(dateEnd2)" v-bind="attrs"
                            v-on="on" hide-details @click:clear="dateEnd2 = null" style="width: 290px;"></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate2" @input="menuEndDate2 = false"
                          @change="dateEnd2 = formatDate(endDate2), CheckDateTime(i.id, startDate2, endDate2)" no-title
                          :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" lg="7" class="py-2">
                      <v-row class="ma-0">
                        <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStockShopHistory()">
                          <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                          ค้นหา
                        </v-btn>
                        <v-spacer />
                        <vue-excel-xlsx id="GetExcelHistory" v-show="false" :data="dataset2"
                          :columns="historyHeaderExcel" :file-name="ExcelhistoryFileName" :sheet-name="'statement'">
                        </vue-excel-xlsx>
                        <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1"
                          @click="ExportExcel('GetExcelHistory')">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>

                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table :headers="header2" :items="dataset2" :items-per-page="9999999999" hide-default-footer
                    class="packhai-border-table hidden-xs-only">
                    <template v-slot:item.referenceNo="{ item }">
                      <a @click="NewPage(item.referenceNo)"> {{ item.referenceNo }} </a>
                    </template>
                    <template v-slot:item.description="{ item }">
                      <font v-if="item.removeQuantity > 0" class="pl-10">{{ item.description }}</font>
                      <span v-else> {{ item.description }} </span>
                    </template>
                    <template v-slot:item.addQuantity="{ item }">
                      <span class="color-green"><b> {{ formatMoney(item.addQuantity) }} </b></span>
                    </template>
                    <template v-slot:item.removeQuantity="{ item }">
                      <span class="color-red"><b> {{ formatMoney(item.removeQuantity) }} </b></span>
                    </template>
                    <template v-slot:item.totalQuantity="{ item }">
                      <b> {{ formatMoney(item.totalQuantity) }} </b>
                    </template>
                  </v-data-table>

                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator :items="dataset2" :items-per-page="9999999999" hide-default-header
                    hide-default-footer class="hidden-sm-and-up">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1">
                              <a @click="NewPage(item.referenceNo)"> {{ item.referenceNo }} </a>
                              <v-spacer />
                              {{ item.createdDatetime }}

                            </v-row>
                            <v-divider />
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <b>นำเข้า</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>นำออก</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>คงเหลือ</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <span class="color-green"><b> {{ item.addQuantity }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <span class="color-red"><b> {{ item.removeQuantity }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b> {{ item.totalQuantity }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>

                <div v-else-if="i.id == 3">
                  <v-row>
                    <v-col cols="12" align="right">
                      <span>
                        <v-btn class="" text icon @click="refresh()">
                          <v-icon size="18">fa-solid fa-arrows-rotate</v-icon>
                        </v-btn>
                      </span>
                      <span>
                        <vue-excel-xlsx id="GetExcelWaitingPack" v-show="false" :data="waitingPackBody.dataset"
                          :columns="waitingPackBody.headerExcel" :file-name="ExcelWaitPackFileName"
                          :sheet-name="'รอแพ็ค'">
                        </vue-excel-xlsx>
                        <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 "
                          @click="ExportExcel('GetExcelWaitingPack')">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>

                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pb-1 ">

                    <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />


                    <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
                      <table style="width:100% ">
                        <tr>
                          <td>

                          </td>
                          <td align="right">

                          </td>
                        </tr>
                      </table>


                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table :headers="waitingPackBody.header" :items="waitingPackBody.dataset"
                    :items-per-page="9999999999" hide-default-footer class="packhai-border-table hidden-xs-only">
                    <template v-slot:item.orderID="{ item }">
                      <a @click="NewPage(item.orderID)"> {{ item.orderID }} </a>
                    </template>
                    <template v-slot:item.created="{ item }">
                      <span> {{ formatDatetime(item.created) }} </span>
                    </template>
                    <template v-slot:item.readyToPackedDate="{ item }">
                      <span> {{ formatDatetime(item.readyToPackedDate) }} </span>
                    </template>
                    <template v-slot:item.expressCompanyName="{ item }">
                      <img v-if="item.expressCompanyLogo != null && item.expressCompanyLogo != ''" width="60px"
                        :src="item.expressCompanyLogo" />
                    </template>
                    <template v-slot:item.referenceName="{ item }">
                      <v-avatar size="25px" tile>
                        <v-img v-if="item.referenceSourcePhoto != null && item.referenceSourcePhoto != ''"
                          :src="item.referenceSourcePhoto" />
                      </v-avatar>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      {{ formatMoney(item.quantity) }}
                    </template>

                  </v-data-table>

                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator :items="waitingPackBody.dataset" :items-per-page="9999999999" hide-default-header
                    hide-default-footer class="hidden-sm-and-up">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1">
                              <a @click="NewPage(item.orderID)"> {{ item.orderID }} </a>
                              <v-spacer />
                              <span> {{ formatDatetime(item.created) }} </span>
                            </v-row>
                            <v-divider />
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <b>สถานะ</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>ช่องทาง</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>ขนส่ง</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <span class="color-green"><b> {{ item.orderStatus }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <span class="color-red"><b> {{ item.referenceName }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b> {{ item.expressCompanyName }} </b>
                              </v-col>
                            </v-row>
                            <v-row class=" py-1">
                              <v-col class="col-4 col-md-2 text-right"> <b>เลขอ้างอิง :</b></v-col>
                              <v-col class="col-8 col-md-6 ">
                                <b> {{ item.referenceNumber }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>
                <div v-else-if="i.id == 4">
                  <v-row>
                    <v-col cols="12" align="right">
                      <span>
                        <v-btn class="" text icon @click="refresh()">
                          <v-icon size="18">fa-solid fa-arrows-rotate</v-icon>
                        </v-btn>
                      </span>
                      <span>
                        <vue-excel-xlsx id="GetExcelWaitExport" v-show="false" :data="exportWaitingBody.dataset"
                          :columns="exportWaitingBody.headerExcel" :file-name="ExcelWaitExportFileName"
                          :sheet-name="'รอนำออก'">
                        </vue-excel-xlsx>
                        <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 "
                          @click="ExportExcel('GetExcelWaitExport')">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>

                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pb-1 ">
                    <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />
                    <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
                      <table style="width:100% ">
                        <tr>
                          <td> </td>
                          <td align="right"> </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table :headers="exportWaitingBody.header" :items="exportWaitingBody.dataset"
                    :items-per-page="9999999999" hide-default-footer class="packhai-border-table hidden-xs-only">

                    <template v-slot:item.index="{ item }">
                      {{ item.no }}
                    </template>
                    <template v-slot:item.id="{ item }">
                      <a v-if="exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)"
                        @click="EditStock(item.id)">
                        {{ GenIE(item.id) }}
                      </a>
                    </template>
                    <template v-slot:item.createdDatetime="{ item }">
                      <span> {{ formatDatetime(item.createdDatetime) }} </span>
                    </template>

                  </v-data-table>
                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator :items="exportWaitingBody.dataset" :items-per-page="9999999999" hide-default-header
                    hide-default-footer class="hidden-sm-and-up">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1"
                              v-if="exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)">
                              <a v-if="exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)"
                                @click="EditStock(item.id)">
                                {{ GenIE(item.id) }}
                              </a>
                              <v-spacer />
                              <span> {{ formatDatetime(item.createdDatetime) }} </span>
                            </v-row>
                            <v-divider />
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <b
                                  v-if="exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)">สร้างโดย</b>

                              </v-col>
                              <v-col cols="6" class="text-center">
                                <b>จำนวน</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <span v-if="item.staffBranchName == null
                                  && exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)"
                                  class="color-green">
                                  ร้านค้า
                                </span>
                                <b
                                  v-else-if="exportWaitingBody.dataset.length - 1 == exportWaitingBody.dataset.indexOf(item)">รวม</b>
                                <span v-else class="color-green"> {{ item.staffBranchName }} </span>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <span class="color-red"><b> {{ item.quantity }} </b></span>
                              </v-col>

                            </v-row>
                            <v-row class=" py-1"
                              v-if="exportWaitingBody.dataset.length - 1 != exportWaitingBody.dataset.indexOf(item)">
                              <v-col class="col-4 col-md-2 text-right"> <b>หมายเหตุ :</b></v-col>
                              <v-col class="col-8 col-md-6 ">
                                <b> {{ item.remark }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>

                </div>

                <!-- **รายการรอนำเข้า -->
                <div v-else-if="i.id == 5">
                  <v-row>
                    <v-col cols="12" align="right">
                      <span>
                        <v-btn class="" text icon @click="refresh()">
                          <v-icon size="18">fa-solid fa-arrows-rotate</v-icon>
                        </v-btn>
                      </span>
                      <span>
                        <vue-excel-xlsx id="GetExcelWaitImport" v-show="false" :data="importWaitingBody.dataset"
                          :columns="importWaitingBody.headerExcel" :file-name="ExcelWaitImportFileName"
                          :sheet-name="'รอนำเข้า'">
                        </vue-excel-xlsx>
                        <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 "
                          @click="ExportExcel('GetExcelWaitImport')">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>

                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pb-1 ">
                    <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />
                    <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
                      <table style="width:100% ">
                        <tr>
                          <td> </td>
                          <td align="right"> </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table :headers="importWaitingBody.header" :items="importWaitingBody.dataset"
                    :items-per-page="9999999999" hide-default-footer class="packhai-border-table hidden-xs-only">

                    <template v-slot:item.index="{ item }">
                      {{ item.no }}
                    </template>
                    <template v-slot:item.id="{ item }">
                      <a v-if="importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)"
                        @click="EditStock(item.id)">
                        {{ GenIE(item.id) }}
                      </a>
                    </template>
                    <template v-slot:item.createdDatetime="{ item }">
                      <span> {{ formatDatetime(item.createdDatetime) }} </span>
                    </template>

                  </v-data-table>
                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator :items="importWaitingBody.dataset" :items-per-page="9999999999" hide-default-header
                    hide-default-footer class="hidden-sm-and-up">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1"
                              v-if="importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)">
                              <a v-if="importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)"
                                @click="EditStock(item.id)">
                                {{ GenIE(item.id) }}
                              </a>
                              <v-spacer />
                              <span> {{ formatDatetime(item.createdDatetime) }} </span>
                            </v-row>
                            <v-divider />
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <b
                                  v-if="importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)">สร้างโดย</b>

                              </v-col>
                              <v-col cols="6" class="text-center">
                                <b>จำนวน</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <span v-if="item.staffBranchName == null
                                  && importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)"
                                  class="color-green">
                                  ร้านค้า
                                </span>
                                <b
                                  v-else-if="importWaitingBody.dataset.length - 1 == importWaitingBody.dataset.indexOf(item)">รวม</b>
                                <span v-else class="color-green"> {{ item.staffBranchName }} </span>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <span class="color-red"><b> {{ item.quantity }} </b></span>
                              </v-col>

                            </v-row>
                            <v-row class=" py-1"
                              v-if="importWaitingBody.dataset.length - 1 != importWaitingBody.dataset.indexOf(item)">
                              <v-col class="col-4 col-md-2 text-right"> <b>หมายเหตุ :</b></v-col>
                              <v-col class="col-8 col-md-6 ">
                                <b> {{ item.remark }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>

                </div>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
    </v-card>



    <!-- แก้ไข รายการนำเข้าสต๊อก -->
    <v-dialog v-model="EditStockShopItem_dialog" max-width="900px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">แก้ไขสินค้ารอบนำเข้าบิลเลขที่ : {{ editedItem.importExportMasterId }}</span>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="closeDialog('EditStockShopItem')"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>รอบวันที่ : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ editedItem.createdDatetime }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>รับเข้า : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ editedItem.quantity }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>คงเหลือ : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ editedItem.quantityLeft }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>RackNo : </strong></v-col>
            <v-col class="col-12 col-md-4">
              <v-text-field v-if="location_list.length == 0" v-model="editedItem.rackNo" outlined hide-details
                ref="locationRackno" :rules="rules.locationRackno">
              </v-text-field>
              <v-combobox v-else v-model="location_selected" :items="location_list" item-text="locationShortName"
                item-value="locationID" return-object @change="(value) => location_selected_changed(value)"
                @keypress="cb_locationKeyPressed($event)">
              </v-combobox>

            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>Lot Name : </strong></v-col>
            <v-col class="col-12 col-md-4">

              <v-text-field v-model="editedItem.lotName" outlined hide-details></v-text-field>

            </v-col>
          </v-row>

          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong> ลำดับตัดสต็อก : </strong></v-col>
            <v-col class="col-12 col-md-4">

              <v-text-field v-model="editedItem.stockSequence" outlined hide-details type="number" :min="1"
                :rules="rules.stockSequence"></v-text-field>

            </v-col>
          </v-row>

          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>วันที่ผลิต : </strong></v-col>
            <v-col class="col-12 col-md-4 ">
              <v-menu v-model="editedItem_menuDate.menuStartDate" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.manufactureDate" outlined readonly prepend-inner-icon="mdi-calendar"
                    @blur="editedItem_menuDate.startDate = parseDate(editedItem.manufactureDate)" v-bind="attrs"
                    v-on="on" hide-details clearable @click:clear="dateStart = null"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem_menuDate.startDate"
                  @input="editedItem_menuDate.menuStartDate = false"
                  @change="editedItem.manufactureDate = formatDate(editedItem_menuDate.startDate)"
                  no-title></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>วันที่หมดอายุ : </strong></v-col>
            <v-col class="col-12 col-md-4">
              <v-menu v-model="editedItem_menuDate.menuEndtDate" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.expirationDate" outlined readonly prepend-inner-icon="mdi-calendar"
                    @blur="editedItem_menuDate.endDate = parseDate(editedItem.expirationDate)" v-bind="attrs" v-on="on"
                    hide-details clearable @click:clear="dateStart = null"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem_menuDate.endDate" @input="editedItem_menuDate.menuEndtDate = false"
                  @change="editedItem.expirationDate = formatDate(editedItem_menuDate.endDate)"
                  no-title></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>


          <v-row class=" py-1">
            <v-col class="col-12 col-md-2 pb-2"> <strong>รายละเอียด :</strong></v-col>
            <v-col class="col-12 col-md-6">
              <v-textarea v-model="editedItem.remark" solo />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right">
          <v-row>
            <v-col class="col-12 text-right">
              <v-btn color="primary" @click="SaveEditStockShopItem()">
                บันทึก
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- เพิ่ม Location  -->
    <v-dialog v-model="SplitStockShopItem_dialog" max-width="900px" persistent>

      <v-card>
        <v-card-title>
          <span class="text-h5">เพิ่ม Location</span>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="closeDialog('SplitStockShopItem')"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>


          <v-row class=" py-1" align="center">
            <v-col class="col-5 col-md-3 pb-2"><strong>เลขที่บิล : </strong></v-col>
            <v-col class="col-7 col-md-9">
              <span>{{ editedItem.importExportMasterId }}</span>
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-5 col-md-3 pb-2"><strong>Lot Name : </strong></v-col>
            <v-col class="col-7 col-md-9">
              <span>{{ editedItem.lotName }}</span>
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-5 col-md-3 pb-2"><strong>วันที่ผลิต : </strong></v-col>
            <v-col class="col-7 col-md-9 ">
              <span>{{ editedItem.manufactureDate }}</span>

            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-5 col-md-3 pb-2"><strong>วันที่หมดอายุ : </strong></v-col>
            <v-col class="col-7 col-md-9">
              <span>{{ editedItem.expirationDate }}</span>

            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-5 col-md-3 pb-2"><strong>จำนวนคงเหลือ : </strong></v-col>
            <v-col class="col-7 col-md-9">

              <span>{{ editedItem.quantityLeft }}</span>
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-3 pb-2"><strong>จำนวนที่ต้องการย้าย : </strong></v-col>
            <v-col class="col-12 col-md-4">

              <v-text-field v-model="editedItem.quantityMove" outlined :max="editedItem.quantityLeft" min="1"
                hide-details type="number" ref="quantityMove" :rules="rules.quantityMove"></v-text-field>

            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-3 pb-2"><strong>RackNo ใหม่ : </strong></v-col>
            <v-col class="col-12 col-md-4">
              <v-text-field v-if="location_list.length == 0" v-model="editedItem.rackNo" outlined hide-details
                ref="locationRackno" :rules="rules.locationRackno"></v-text-field>



              <v-combobox v-else v-model="location_selected2" :items="location_list2" item-text="locationShortName"
                item-value="locationID" return-object @change="(value) => location_selected_changed2(value)"
                @keypress="cb_locationKeyPressed($event)">
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="text-right">
            <v-col class="col-12">
              <v-btn color="primary" @click="SaveSplitStockShopItem()">
                บันทึก
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { header_token } from '@/website/token'
import Loading from '@/website/components/Loading'
import { stockService_dotnet, branchService_dotnet } from '@/website/global'
import { AlertError } from '@/website/global_alert'
import { formatDatetime, formatDatetime1, formatDate_thai, formatDate_thai1, formatMoney, isNumberWithDot, isNumberWNoDot, DateNowNotNull, CheckUrlPhoto, GenIE, GenPA, Trim_value, Trim_rules, getLocation } from '@/website/global_function'
export default {
  components: {
    Loading,
  },
  data: vm => ({
    page_loading: true,
    loading: false,
    BranchID: parseInt(localStorage.getItem('Branch_BranchID')),
    ExcelhistoryFileName: null,
    ExcelWaitPackFileName: null,
    ExcelWaitImportFileName: null,
    ExcelWaitExportFileName: null,
    ExcelStockShopFileName: null,
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: '',

    // ข้อมูลสินค้า
    stockShopId: 0,
    stockShop: [],
    shopId: 0,

    // tab
    tab: 0,
    tab_list: [
      { "id": 1, "name": "รายการนำเข้าสต๊อก" },
      { "id": 2, "name": "รายการเดินสต๊อก" },
      { "id": 3, "name": "รายการรอแพ็ค" },
      { "id": 4, "name": "รายการรอนำออก" },
      { "id": 5, "name": "รายการรอนำเข้า" },
    ],

    header1: [

      { text: 'แก้ไข', align: 'center', sortable: false, value: 'actions', width: '80px' },
      { text: 'เพิ่ม Location', align: 'center', sortable: false, value: 'add', width: '80px' },
      { text: 'ปริ้น', align: 'center', sortable: false, value: 'print', width: '80px' },
      { text: 'วันที่', align: 'center', sortable: false, value: 'createdDatetime', width: '170px' },
      { text: 'เลขที่บิลนำเข้า', align: 'center', sortable: false, value: 'importExportMasterId', width: '150px' },
      { text: 'Lot Name', align: 'center', sortable: false, value: 'lotName', width: '110px' },
      { text: 'ลำดับตัดสต็อก', align: 'center', sortable: false, value: 'stockSequence', width: '150px' },
      { text: 'จำนวนที่รับเข้า', align: 'center', sortable: false, value: 'quantity', width: '130px' },
      { text: 'จำนวนที่เหลือ', align: 'center', sortable: false, value: 'quantityLeft', width: '120px' },
      { text: 'วันที่ผลิต', align: 'center', sortable: false, value: 'manufactureDate', width: '170px' },
      { text: 'วันที่หมดอายุ', align: 'center', sortable: false, value: 'expirationDate', width: '170px' },
      { text: 'RackNo', align: 'center', sortable: false, value: 'rackNo', width: '120px' },
      { text: 'รายละเอียด', align: 'left', sortable: false, value: 'remark', width: '170px' },
    ],
    dataset1: [],
    obj_Full_dataset1: [],
    startDate: null,
    dateStart: null,
    menuStartDate: false,
    endDate: null,
    dateEnd: null,
    menuEndDate: false,
    search: {
      "warehouseList": [{ "id": 0, "name": "--ทั้งหมด--" }],
      "warehouse": { "id": 0, "name": "--ทั้งหมด--" },
      "showProductList": [{ "id": 0, "name": "--ทั้งหมด--" }, { "id": 1, "name": "สินค้าที่มีสต๊อก" }, { "id": 2, "name": "สินค้าที่ไม่มีสต๊อก" }],
      "showProduct": { "id": 0, "name": "--ทั้งหมด--" },
      "startDate": null,
      "endDate": null,
    },

    header2: [
      { text: 'วันที่', align: 'center', sortable: false, value: 'createdDatetime', width: '170px' },
      { text: 'เลขอ้างอิง', align: 'center', sortable: false, value: 'referenceNo', width: '110px' },
      { text: 'รายละเอียด', align: 'left', sortable: false, value: 'description', },
      { text: 'เข้า', align: 'right', sortable: false, value: 'addQuantity', width: '90px' },
      { text: 'ออก', align: 'right', sortable: false, value: 'removeQuantity', width: '90px' },
      { text: 'คงเหลือ', align: 'right', sortable: false, value: 'totalQuantity', width: '90px' },
      { text: 'เลขอ้างอืง', align: 'right', sortable: false, value: 'referenceNo2', width: '90px' },
    ],
    dataset2: [],
    startDate2: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10),
    dateStart2: vm.formatDate(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10)),
    menuStartDate2: false,
    endDate2: new Date().toISOString().substr(0, 10),
    dateEnd2: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menuEndDate2: false,
    search2: {
      "warehouseList": [],
      "warehouse": { "id": 0, "name": "--ทั้งหมด--" },
      "startDate": null,
      "endDate": null,
    },

    waitingPackBody: {
      header: [
        { text: 'No', align: 'center', sortable: false, value: 'no', width: '70px' },
        { text: 'วันที่เปิดออเดอร์', align: 'center', sortable: false, value: 'created', width: '150px' },
        { text: 'พร้อมแพ็ค', align: 'center', sortable: false, value: 'readyToPackedDate', width: '150px' },
        { text: 'ออเดอร์', align: 'center', sortable: false, value: 'orderID', width: '110px' },
        { text: 'สถานะ', align: 'center', sortable: false, value: 'orderStatus', width: '90px' },
        { text: 'ช่องทาง', align: 'center', sortable: false, value: 'referenceName', width: '90px' },
        { text: 'ขนส่ง', align: 'center', sortable: false, value: 'expressCompanyName', width: '90px' },
        { text: 'เลขอ้างอิง', align: 'center', sortable: false, value: 'referenceNumber', width: '110px' },
        { text: 'จำนวน', align: 'center', sortable: false, value: 'quantity', width: '110px' },
      ],
      dataset: [],
      headerExcel: [
        { label: "No", field: "no", width: 30 },
        { label: "วันที่เปิดออเดอร์", field: "createdDatetimeExcel", width: 30 },
        { label: "พร้อมแพ็ค", field: "readyToPackedDateExcel", width: 30 },
        { label: "ออเดอร์", field: "orderID", width: 15 },
        { label: "สถานะ", field: "orderStatus", width: 30 },
        { label: "ช่องทาง", field: "referenceName", width: 15 },
        { label: "ขนส่ง", field: "expressCompanyName", width: 15 },
        { label: "เลขอ้างอิง", field: "referenceNumber", width: 15 },
        { label: "จำนวน", field: "quantity", width: 15 },
      ],
    },


    importWaitingBody: {
      header: [

        { text: 'No', align: 'center', sortable: false, value: 'index', width: '70px' },
        { text: 'เลขที่บิล', align: 'center', sortable: false, value: 'id', width: '150px' },
        { text: 'วัน', align: 'center', sortable: false, value: 'createdDatetime', width: '150px' },
        { text: 'สร้างโดย', align: 'center', sortable: false, value: 'staffBranchName', width: '150px' },
        { text: 'จำนวน', align: 'center', sortable: false, value: 'quantity', width: '150px' },
        { text: 'หมายเหตุ', align: 'center', sortable: false, value: 'remark', width: '150px' },

      ],
      dataset: [],
      headerExcel: [
        { label: "No", field: "no", width: 30 },
        { label: "เลขที่บิล", field: "IDNumber", width: 30 },
        { label: "วัน", field: "createdDatetimeExcel", width: 30 },
        { label: "สร้างโดย", field: "staffBranchName", width: 30 },
        { label: "จำนวน", field: "quantity", width: 30 },
        { label: "หมายเหตุ", field: "remark", width: 30 },


      ],
    },
    exportWaitingBody: {
      header: [

        { text: 'No', align: 'center', sortable: false, value: 'index', width: '70px' },
        { text: 'เลขที่บิล', align: 'center', sortable: false, value: 'id', width: '150px' },
        { text: 'วัน', align: 'center', sortable: false, value: 'createdDatetimeExcel', width: '150px' },
        { text: 'สร้างโดย', align: 'center', sortable: false, value: 'staffBranchName', width: '150px' },
        { text: 'จำนวน', align: 'center', sortable: false, value: 'quantity', width: '150px' },
        { text: 'หมายเหตุ', align: 'center', sortable: false, value: 'remark', width: '150px' },

      ],
      dataset: [],
      headerExcel: [
        { label: "No", field: "no", width: 30 },
        { label: "เลขที่บิล", field: "IDNumber", width: 30 },
        { label: "วัน", field: "createdDatetimeExcel", width: 30 },
        { label: "สร้างโดย", field: "staffBranchName", width: 30 },
        { label: "จำนวน", field: "quantity", width: 30 },
        { label: "หมายเหตุ", field: "remark", width: 30 },
      ],
    },

    editedItem_menuDate: { menuStartDate: false, menuEndtDate: false, dateStart: null, dateEnd: null },

    // Export Excel
    loadingExcel: 0,
    historyHeaderExcel: [
      { label: "วันที่", field: "createdDatetimeExcel", width: 30 },
      { label: "เลขอ้างอิง", field: "referenceNo", width: 15 },
      { label: "รายละเอียด", field: "description", width: 30 },
      { label: "เข้า", field: "addQuantity", width: 15 },
      { label: "ออก", field: "removeQuantity", width: 15 },
      { label: "คงเหลือ", field: "totalQuantity", width: 15 },
    ],
    StockShopItemHeaderExcel: [
      { label: 'วันที่', field: 'createdDatetimeExcel', width: '170px' },
      { label: 'เลขที่บิลนำเข้า', field: 'importExportMasterId', width: '150px' },
      { label: 'Lot Name', field: 'lotName', width: '110px' },
      { label: 'ลำดับตัดสต็อก', field: 'stockSequence', width: '150px' },
      { label: 'จำนวนที่รับเข้า', field: 'quantity', width: '130px' },
      { label: 'จำนวนที่เหลือ', field: 'quantityLeft', width: '120px' },
      { label: 'วันที่ผลิต', field: 'manufactureDateExcel', width: '170px' },
      { label: 'วันที่หมดอายุ', field: 'expirationDateExcel', width: '170px' },
      { label: 'RackNo', field: 'rackNo', width: '120px' },
      { label: 'รายละเอียด', field: 'remark', width: '170px' },
    ],
    editedIndex: [],
    EditStockShopItem_dialog: false,
    SplitStockShopItem_dialog: false,
    editedItem: {},
    AddLocationItem: {},
    // บังคับกรอก
    rules: {
      locationRackno: [val => (val || '').length > 0],
      quantityMove: [val => (val || '' || '0').length > 0],
      newLocation: [val => val > 0],
      stockSequence: [val => val >= 0],
    },
    location_list: [],
    location_selected: null,
    isLocationPass: false,
    isButtonUpdatePressed: false,

    location_list2: [],
    location_selected2: null,
  }),


  async created() {
    document.title = ""
    this.location_list = await this.getLocation();
    if (this.location_list.length > 0) {
      this.location_list2 = JSON.parse(JSON.stringify(this.location_list));
    }
    this.stockShopId = this.$route.query.id
    await this.Load_Data(this.stockShopId)
    document.title = this.stockShop.name
    this.page_loading = false
  },
  computed: {
    checkLength() {
      var length = this.editItem.locationList == undefined ? 0 : this.editItem.locationList.length
      // var length =  1
      return length;
    },

  },
  methods: {
    async Load_Data(stockShopId) {

      await axios.all([
        axios.post(stockService_dotnet + 'Stock/get-stockShop', {
          "stockShopId": parseInt(stockShopId),
        }, { headers: header_token }),

        axios.post(branchService_dotnet + 'Branch/get-warehouse-list', {
          "branchId": this.BranchID,
        }, { headers: header_token }),

        axios.post(stockService_dotnet + 'Stock/inventories-group', {
          "stockShopId": parseInt(stockShopId),
        }, { headers: header_token }),

        axios.post(stockService_dotnet + 'Stock/get-stockshop-item-list', {
          "stockShopId": parseInt(stockShopId),
        }, { headers: header_token }),

        axios.post(stockService_dotnet + 'Stock/get-stockshop-history-list', {
          "stockShopId": parseInt(stockShopId),
        }, { headers: header_token }),

        axios.post(stockService_dotnet + 'Stock/get-unpacked-by-stockshop', {
          "stockShopId": parseInt(stockShopId),
        }, { headers: header_token }),
      ])
        .then(axios.spread(async (stockShop, warehouseList, inventories, stockItem, stockHistory, waitingPack) => {
          if (stockShop.status == 200 && warehouseList.status == 200 && inventories.status == 200 && stockItem.status == 200 && stockHistory.status == 200, waitingPack.status == 200) {
            stockShop = stockShop.data
            stockShop.stockFullname = ((stockShop.sku == null || stockShop.sku == "") ? "" : stockShop.sku + "_") + stockShop.name + ((stockShop.prop1Description == null || stockShop.prop1Description == "") ? "" : " " + stockShop.prop1Description + ((stockShop.prop2Description == null || stockShop.prop2Description) ? "" : " " + stockShop.prop2Description))
            inventories = inventories.data
            stockHistory = stockHistory.data
            stockItem = stockItem.data
            waitingPack = waitingPack.data
            this.ExcelhistoryFileName = "รายการเดินสต๊อก " + stockShop.stockFullname
            this.ExcelWaitPackFileName = "รายการรอแพ็ค " + stockShop.stockFullname
            this.ExcelWaitImportFileName = "รายการรอนำเข้า " + stockShop.stockFullname
            this.ExcelWaitExportFileName = "รายการรอนำออก " + stockShop.stockFullname
            this.ExcelStockShopFileName = "รายการรอบนำเข้า " + stockShop.stockFullname
            // ไว้เทสดูข้อมูล
            //  this.getimportexportListByStockShopId(957,634);

            //  ยิง API รายการรอนำเข้า,รายการรอนำออก
            this.getimportexportListByStockShopId(stockShopId, stockShop.warehouseId);


            var stockItemTemp = []
            for (var j in stockItem.itemList) {
              if (stockItem.itemList[j].stockShopId == this.stockShopId) {
                stockItemTemp.push(stockItem.itemList[j])
              }
            }
            stockItem.itemList = stockItemTemp

            this.stockShop = stockShop
            this.dataset = inventories
            this.dataset1 = this.SetDataStockItem(stockItem.itemList)
            this.obj_Full_dataset1 = stockItem.itemList
            this.dataset2 = this.SetDataHistoryExcel(stockHistory.itemList)

            for (var i in warehouseList.data) {
              var warehouse = { "id": warehouseList.data[i].id, "name": warehouseList.data[i].name }
              this.search.warehouseList.push(warehouse)
              this.search2.warehouseList.push(warehouse)
              if (parseInt(warehouseList.data[i].id) == parseInt(stockShop.warehouseId)) {
                this.search2.warehouse = warehouse
              }
              if (stockItem.warehouseID == warehouseList.data[i].id) {
                this.search.warehouse = warehouse
              }
            }
            for (var k in this.search.showProductList) {
              if (stockItem.inStock == this.search.showProductList[k].id) {
                this.search.showProduct = this.search.showProductList[k]
                break
              }
            }


            this.waitingPackBody.dataset = this.SetDataWaitingPack(waitingPack)

            this.SearchDateDataset1(stockItem)
            this.SearchDateDataset2(stockHistory)
          } else {
            this.loading = false
            this.AlertError()
          }

        }));
    },
    SearchDateDataset1(data) {
      this.dateStart = this.formatDatetime(data.startDate)
      this.startDate = data.startDate
      this.dateEnd = this.formatDatetime(data.endDate)
      this.endDate = data.endDate
    },
    SearchDateDataset2(data) {
      this.dateStart2 = this.formatDatetime(data.startDate)
      this.startDate2 = data.startDate
      this.dateEnd2 = this.formatDatetime(data.endDate)
      this.endDate2 = data.endDate
    },
    async SeeDetails(warehouseId, e) {
      if (e) {
        for (var i in this.search.warehouseList) {
          if (warehouseId == this.search.warehouseList[i].id) {
            this.search.warehouse = this.search.warehouseList[i]
            this.dateStart = null
            this.startDate = null
            this.dateEnd = null
            this.endDate = null
            await this.SearchStockShopItem()
            await this.SetSearchDateDataset1(this.dataset1)
          }
        }
        this.tab = 1
      } else {
        this.tab = warehouseId
      }
    },
    SetSearchDateDataset1(data) {
      if (data.length > 1) {
        this.dateStart = data[data.length - 2].createdDatetime.substr(0, 10)
        this.startDate = this.parseDate(data[data.length - 2].createdDatetime.substr(0, 10))
        this.dateEnd = data[0].createdDatetime.substr(0, 10)
        this.endDate = this.parseDate(data[0].createdDatetime.substr(0, 10))
      }
    },
    async SearchStockShopHistory() {
      this.loading = true
      this.search2.startDate = this.startDate2
      this.search2.endDate = this.endDate2
      let response = await axios.post(stockService_dotnet + 'Stock/get-stockshop-history-list', {
        "stockShopId": this.stockShopId,
        "warehouseId": this.search2.warehouse.id,
        "startDate": this.startDate2,
        "endDate": this.endDate2,
      }, { headers: header_token })
      this.loading = false
      if (response.status == 200) {
        this.dataset2 = await this.SetDataHistoryExcel(response.data.itemList)
      }
    },
    async SearchStockShopItem() {
      this.loading = true
      if (this.startDate == null || this.endDate == null) {
        this.dateStart = null
        this.startDate = null
        this.dateEnd = null
        this.endDate = null
      }
      this.search.startDate = this.startDate
      this.search.endDate = this.endDate
      let response = await axios.post(stockService_dotnet + 'Stock/get-stockshop-item-list', {
        "stockShopId": this.stockShopId,
        "warehouseId": this.search.warehouse.id,
        "inStock": this.search.showProduct.id,
        "startDate": this.startDate,
        "endDate": this.endDate,
      }, { headers: header_token })
      this.loading = false
      if (response.status == 200) {
        var Temp = []
        for (var j in response.data.itemList) {
          if (response.data.itemList[j].stockShopId == this.stockShopId) {
            Temp.push(response.data.itemList[j])
          }
        }
        response.data.itemList = Temp
        this.dataset1 = await this.SetDataStockItem(response.data.itemList)
        this.obj_Full_dataset1 = response.data.itemList
      }
    },
    SetDataStockItem(data) {
      if (data.length > 0) {
        var response = []
        var TotalQuantity = 0
        var TotalQuantityLeft = 0
        var TotalCostPrice = 0
        var TotalPrice = 0

        for (var i in data) {
          data[i].costPrice = data[i].costPrice == null || data[i].costPrice == '' ? 0 : parseFloat(data[i].costPrice)
          data[i].quantity = data[i].quantity == null || data[i].quantity == '' ? 0 : parseInt(data[i].quantity)
          data[i].quantityLeft = data[i].quantityLeft == null || data[i].quantityLeft == '' ? 0 : parseInt(data[i].quantityLeft)

          response.push({
            createdDatetime: this.formatDatetime(data[i].createdDatetime),
            createdDatetimeExcel: this.formatDatetime1(data[i].createdDatetime),
            quantity: data[i].quantity,
            quantityLeft: data[i].quantityLeft,
            costPrice: data[i].costPrice,
            totalPrice: data[i].quantityLeft * data[i].costPrice,
            manufactureDate: this.formatDate_thai(data[i].manufactureDate),
            expirationDate: this.formatDate_thai(data[i].expirationDate),
            manufactureDateExcel: this.formatDate_thai1(data[i].manufactureDate),
            expirationDateExcel: this.formatDate_thai1(data[i].manufactureDate),
            rackNo: data[i].rackNo,
            remark: data[i].remark,
            importExportMasterId: this.GenIE(data[i].importExportMasterId),
            lotName: data[i].lotName,
            stockShopItemId: data[i].stockShopItemId,
            locationID: data[i].locationID,

            stockSequence: data[i].stockSequence//
          })

          TotalQuantity += data[i].quantity
          TotalQuantityLeft += data[i].quantityLeft
          TotalCostPrice += data[i].costPrice
          TotalPrice += data[i].quantityLeft * data[i].costPrice
        }
        console.log(response);
        response.push({
          createdDatetime: "รวม",
          quantity: TotalQuantity,
          quantityLeft: TotalQuantityLeft,
          costPrice: TotalCostPrice,
          totalPrice: TotalPrice,
          manufactureDate: "",
          expirationDate: "",
          rackNo: "",
          remark: "",
          stockSequence: ""  //
        })

        return response
      } else {
        return data
      }
    },
    async ExportExcel(excelname) {
      document.getElementById(excelname).click();
    },
    SetDataHistoryExcel(data) {
      var response = []
      for (var i in data) {
        response.push({
          createdDatetime: this.formatDatetime(data[i].createdDatetime),
          createdDatetimeExcel: this.formatDatetime1(data[i].createdDatetime),
          referenceNo: this.CheckReferenceNo(data[i].referenceNo),
          referenceNo2: this.CheckReferenceNo(data[i].referenceNo2),
          description: data[i].description,
          addQuantity: data[i].addQuantity,
          removeQuantity: data[i].removeQuantity,
          totalQuantity: data[i].totalQuantity,
        })
      }
      return response
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo
      this.ShowPhoto = true
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    toBack() {
      this.$router.push('/my-stock')
    },
    UpdateStock() {
      var data = {
        "productMasterId": this.stockShop.productMasterId,
        "productMasterItemId": this.stockShop.productMasterItemId,
        "isProductSet": 0,
      }
      localStorage.setItem('data', JSON.stringify(data))
      window.open("/my-product/update");
      // this.$router.push("/my-product/update")
    },
    async NewPage(ReferenceNo) {
      if (ReferenceNo == null) { return ReferenceNo }
      var paie = ReferenceNo.substr(0, 2)
      var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length))
      if (paie == 'PA') {
         
        window.open("order-detail?id=" +ID);
       
      } else if (paie == 'IE') {
        window.open("transfer-list/update?id=" + btoa(ID));
      }
    },
    CheckReferenceNo(ReferenceNo) {
      if (ReferenceNo == null) { return ReferenceNo }
      var paie = ReferenceNo.substr(0, 2)
      var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length))
      if (paie == 'PA') {
        return this.GenPA(ID)
      } else if (paie == 'IE') {
        return this.GenIE(ID)
      } else {
        return ReferenceNo
      }
    },
    CheckDateTime(tab, startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate
        var PendDate = endDate
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate
          PendDate = startDate
        }
        startDate = PstartDate
        endDate = PendDate
      }
      if (tab == 1) {
        this.startDate = startDate
        this.endDate = endDate
        this.dateStart = this.formatDate(startDate)
        this.dateEnd = this.formatDate(endDate)
      }
      if (tab == 2) {
        this.startDate2 = startDate
        this.endDate2 = endDate
        this.dateStart2 = this.formatDate(startDate)
        this.dateEnd2 = this.formatDate(endDate)
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    editItem(item, index) {

      this.editedIndex = this.dataset1.indexOf(item)
      this.editedItem = Object.assign({}, item)


      this.openDialog(index)

    },
    async openDialog(index) {
      this.loading=true;
      this.location_list =await this.getLocation();
      if (index == "EditStockShopItem") {

        if (this.location_list.length > 0) {
          let locationfound = null;
          let findByText = false;
          if (this.editedItem.locationID != null) {
            locationfound = this.location_list.find(item => item.locationID == this.editedItem.locationID);
            if (locationfound != null) {
              this.location_selected = locationfound;
            } else {
              findByText = true;
            }
          } else {
            findByText = true;
          }

          if (findByText && this.editedItem.rackNo) {
            locationfound = this.location_list.find(item => item.locationShortName == this.editedItem.rackNo);
            if (locationfound != null) {
              this.location_selected = locationfound;
              this.editedItem.locationID = locationfound.locationID;
            } else {
              this.editedItem.locationID = 0;
              this.location_selected = {
                locationID: 0,
                locationName: '',
                locationShortName: this.editedItem.rackNo
              }
            }
          } else if (findByText && !this.editedItem.rackNo) {
            this.location_selected = null;
          }
        }
        this.isLocationPass = true;
        this.isButtonUpdatePressed = false;
        this.EditStockShopItem_dialog = true
      }
      else if (index == "SplitStockShopItem") {

        this.location_selected2 = null;
        this.editedItem.rackNo = null;
        this.editedItem.locationID = null;

        this.isLocationPass = true;
        this.isButtonUpdatePressed = false;
        this.SplitStockShopItem_dialog = true
      }
      this.loading=false;
    },
    cb_locationKeyPressed(event) {
      this.isLocationPass = false;
    },
    location_selected_changed(val) {


      if (typeof val === 'object' && val != null) {
        this.location_selected = val;
        this.editedItem.rackNo = this.location_selected.locationShortName;
        this.editedItem.locationID = this.location_selected.locationID;
        this.isLocationPass = true;
      }
      else {
        if (val) {
          this.location_selected = {
            locationID: 0,
            locationName: val,
            locationShortName: val
          }
          this.editedItem.rackNo = this.location_selected.locationShortName;
          this.editedItem.locationID = this.location_selected.locationID;
        } else {
          this.location_selected = null;
          this.editedItem.rackNo = null;
          this.editedItem.locationID = null;

        }
        this.isLocationPass = true;
        if (this.isButtonUpdatePressed) {
          this.SaveEditStockShopItem2();
        }
      }
    },

    async SaveEditStockShopItem() {
      this.isButtonUpdatePressed = true;
      if (this.location_list.length > 0) {
        if (this.isLocationPass) {
          this.SaveEditStockShopItem2();
        }
      } else {
        this.SaveEditStockShopItem2();
      }
    },
    async SaveEditStockShopItem2() {
      await Object.assign(this.dataset1[this.editedIndex], this.editedItem)
      this.loading = true;
      let bodyItemList = this.dataset1[this.editedIndex];

      var body = {
        "branchID": this.BranchID,
        "stockShopId": parseInt(this.stockShopId),
        "stockShopItemId": parseInt(bodyItemList.stockShopItemId),
        "manufactureDate": !bodyItemList.manufactureDate ? null : bodyItemList.manufactureDate = this.parseDate(bodyItemList.manufactureDate),
        "expirationDate": !bodyItemList.expirationDate ? null : bodyItemList.expirationDate = this.parseDate(bodyItemList.expirationDate),
        "rackNo": bodyItemList.rackNo,
        "lotName": bodyItemList.lotName,
        "remark": bodyItemList.remark,
        "costPrice": bodyItemList.costPrice,
        "locationID": bodyItemList.locationID,
        "stockSequence": parseInt(bodyItemList.stockSequence) > 0 ? parseInt(bodyItemList.stockSequence) : null
      }

      var response = await axios.post(stockService_dotnet + 'Stock/update-stockshop-item-list', body, { headers:  header_token })
      this.loading = false;
      if (response.data.status) {
        Swal.fire({
          toast: true,
          position: 'top',
          timer: 1500,
          icon: 'success',
          text: 'บันทึกสำเร็จ',
        });
        this.SearchStockShopItem();
        this.EditStockShopItem_dialog = false
      } else {
        Swal.fire({
          icon: 'error',
          title: 'แก้ไขข้อมูลไม่สำเร็จ',
          text: response.data.description,
        })
      }


    },
    location_selected_changed2(val) {

      if (typeof val === 'object' && val != null) {
        this.location_selected2 = val;
        this.editedItem.rackNo = this.location_selected2.locationShortName;
        this.editedItem.locationID = this.location_selected2.locationID;
        this.isLocationPass = true;
      }
      else {
        if (val) {
          this.location_selected2 = {
            locationID: 0,
            locationName: val,
            locationShortName: val
          }
          this.editedItem.rackNo = this.location_selected2.locationShortName;
          this.editedItem.locationID = this.location_selected2.locationID;
        } else {
          this.location_selected2 = null;
          this.editedItem.rackNo = null;
          this.editedItem.locationID = null;

        }
        this.isLocationPass = true;
        if (this.isButtonUpdatePressed) {
          this.SaveSplitStockShopItem2();
        }
      }
    },
    async SaveSplitStockShopItem() {
      this.isButtonUpdatePressed = true;
      if (this.location_list2.length > 0) {
        if (this.isLocationPass) {
          this.SaveSplitStockShopItem2();
        }
      } else {
        this.SaveSplitStockShopItem2();
      }
    },
    async SaveSplitStockShopItem2() {
      if (await this.checkVariableDialog()) {
        await axios.post(stockService_dotnet + 'Stock/split-stockshop-item', {
          "stock_shop_item_id": this.editedItem.stockShopItemId,
          "user_id": localStorage.getItem('Branch_StaffID'),
          "branchID": this.BranchID,
          "qauantity": this.editedItem.quantityMove,
          "rackNo": this.editedItem.rackNo,
          "locationID": this.editedItem.locationID
        }, {
          headers: header_token
        }).then(async (res) => {
          this.dataset1 = await this.SetDataStockItem(res.data.itemList),
            this.closeDialog('SplitStockShopItem')
        }).catch((error) => {

          Swal.fire({
            icon: 'error',
            title: 'แก้ไขข้อมูลไม่สำเร็จ',
            text: 'กรุณาลองใหม่อีกครั้ง ' + error,
          })

        });
      }
    },
    async refresh() {
      this.loading = true;
      await this.Load_Data(this.stockShopId)
      this.loading = false;
    },
    async ClickToPrint(obj) {
      const GenToURL = this.obj_Full_dataset1.filter(item => item.stockShopItemId === obj.stockShopItemId).map(item => item);
      //เก็บค่าที่เลือก ไว้ใน sessionStorage
      sessionStorage.setItem('selectedStockBody', JSON.stringify(GenToURL));

      let query = JSON.stringify(GenToURL.productMasterItemId);
      let encodedQuery = encodeURIComponent(query);
      window.open(`/my-stock/printProductDetails?productMasterItemId=${encodedQuery}`, "myWindow", "width=1000,height=1000");

    },

    closeDialog(index) {
      switch (index) {
        case "EditStockShopItem":
          this.EditStockShopItem_dialog = false
          break;
        case "SplitStockShopItem":
          this.SplitStockShopItem_dialog = false
          break;
        default:
      }

    },

    async checkVariableDialog() {
      if (!this.editedItem.quantityMove || this.editedItem.quantityMove <= 0 || parseInt(this.editedItem.quantityMove) >= this.editedItem.quantityLeft) {
        var message = '';
        if (!this.editedItem.quantityMove || this.editedItem.quantityMove <= 0) {
          message = "กรุณาระบุจำนวนที่ต้องการย้าย";
        }
        else if (parseInt(this.editedItem.quantityMove) >= parseInt(this.editedItem.quantityLeft)) {
          message = "จำนวนที่ต้องการย้าย ต้องน้อยกว่า "+this.editedItem.quantityLeft;
        }  
        this.$vuetify.goTo(this.$refs.quantityMove, { offset: 50 })
        this.$refs.quantityMove.focus()
        console.log(message);

        Swal.fire({
          icon: 'error',
          text: message,
        });
        return false
      }

      return true
    },
    SetDataWaitingPack(data) {
      if (data.length > 0) {
        var response = []
        var TotalQuantity = 0
        var counter = 1
        for (var i in data) {
          data[i].IDNumber = this.GenIE(data[i].id)
          data[i].no = counter
          counter++


          data[i].createdDatetimeExcel = this.formatDatetime1(data[i].createdDatetime)

          if (data[i].staffBranchName == null) {
            data[i].staffBranchName = "ร้านค้า"
          }
          if (data[i].readyToPackedDate != null) {
            data[i].readyToPackedDateExcel = this.formatDatetime1(data[i].readyToPackedDate)
          }
          if (data[i].created != null) {
            data[i].createdDatetimeExcel = this.formatDatetime1(data[i].created)
          }
          TotalQuantity += data[i].quantity
          response.push(
            data[i]
          )
        }

        response.push({
          no: "รวม",
          staffBranchName: "",
          quantity: TotalQuantity,

        })

        return response
      } else {
        return data
      }
    },
    async getimportexportListByStockShopId(stockShopId, warehouseID) {
      var importDatatList = await this.SetData_importexportmasterListByStockShopId(stockShopId, warehouseID, [1, 3])
      var exportDatatList = await this.SetData_importexportmasterListByStockShopId(stockShopId, warehouseID, [2, 4])
      this.importWaitingBody.dataset = this.SetDataWaitingPack(importDatatList)
      this.exportWaitingBody.dataset = this.SetDataWaitingPack(exportDatatList)
    },
    async SetData_importexportmasterListByStockShopId(stockShopId, warehouseID, transacionType) {
      return axios.post(stockService_dotnet + 'Stock/get-importexportmaster-list-by-stockshopid', {
        "stockShopId": parseInt(stockShopId),
        "warehouseID": parseInt(warehouseID),
        "transacionType": transacionType,
      }, { headers: header_token })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return [{}];
        });

    },
    async updateRackNoStockShopFollowStockSequence(stockShopId) {
      return await axios.post(stockService_dotnet + 'Stock/update-rackNo-follow-stocksequence', {
        "stockShopID": parseInt(this.stockShopId),
      }, { headers: header_token })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return [{}];
        });
    },
    async EditStock(ImportExportMasterID) {
      window.open("transfer-list/update?id=" + btoa(ImportExportMasterID));
    },
    allowedDates: val => val <= new Date().toISOString().substr(0, 10),
    formatDatetime,
    formatDatetime1,
    formatDate_thai,
    formatDate_thai1,
    formatMoney,
    isNumberWithDot,
    isNumberWNoDot,
    DateNowNotNull,
    CheckUrlPhoto,
    GenIE,
    GenPA,
    Trim_value,
    Trim_rules,
    AlertError,
    getLocation
  }
}
</script>

<style scoped>
>>>.v-select__slot {
  height: 40px;
}

>>>.v-input__prepend-inner {
  margin-top: 10px;
}

.setCenterIcon {
  padding: 10px 0px 0px 0px;
  margin: 0px -45px 0px 0px;
}
</style>
