<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        <v-row>
          <v-col cols="6">
            สต๊อก
            <v-icon>mdi-chevron-right</v-icon>
            ข้อมูลสินค้า
          </v-col>
          <v-col cols="6" class="text-right">
            <vue-excel-xlsx id="GetExcel" v-show="false" :data="datasetExcel" :columns="headerExcel"
              :file-name="ExcelFileName" :sheet-name="'shee1'">
            </vue-excel-xlsx>
            <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel()">
              <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
              <span class="fn-12"> Export Excel </span>
              <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
        <v-spacer />
        <v-btn :elevation="1" color="success" class="pa-2 mr-2 "
          @click.native="$router.push('/my-product/update/excel')">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่ Excel
        </v-btn>
        <v-btn :elevation="1" color="primary" class="pa-2" @click="UpdateStock(null)">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-select v-model="search.shop" :items="search.shopList" item-text="name" item-value="id" persistent-hint
              return-object dense hide-details outlined single-line></v-select>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> บาร์โค้ด : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.barcode" @change="value => search.barcode = Trim_value(value)" placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
              @keyup.enter="SearchStock()" outlined dense hide-details autocomplete="off"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> เช็ตสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-select v-model="search.isProductSetTemp" :items="search.isProductSetList" item-text="name"
              item-value="id" persistent-hint return-object dense hide-details outlined single-line></v-select>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> SKU : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.sku" @change="value => search.sku = Trim_value(value)" placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
              @keyup.enter="SearchStock()" outlined dense hide-details autocomplete="off"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> รหัสสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.productCode" @change="value => search.productCode = Trim_value(value)"
              @keyup.enter="SearchStock()" outlined dense hide-details autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="12" md="2" lg="1" />
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStock()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>

              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                ร้านค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.shop"
                  :items="search.shopList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                เช็ตสินค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.isProductSetTemp"
                  :items="search.isProductSetList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                รหัสสินค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.productCode"
                  @change="value => search.productCode = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                บาร์โค้ด :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.barcode"
                  @change="value => search.barcode = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                SKU :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.sku"
                  @change="value => search.sku = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStock()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <vue-excel-xlsx
                    id="GetExcel"
                    v-show="false"
                    :data="datasetExcel"
                    :columns="headerExcel"
                    :filename="'myproduct'"
                    :sheetname="'shee1'"
                    >
                </vue-excel-xlsx>
                <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel()">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

      </v-card-text>
    </v-card>

    <!-- แสดงแถวข้อมูล -->
    <v-row class="ma-0">
      <v-col cols="6" class="pa-0 ">
        ทั้งหมด {{ formatMoney(total) }} แถว
      </v-col>
      <v-col cols="6"  class="pa-0  hidden-xs-only  " align="right" >
        <v-btn   color="" class="pa-2 ml-2 mt-1"  icon @click="changeFormat_DataTabel()">
              <v-icon v-if="ViewType==1" size="16" class="pr-1">fa-table</v-icon>
              <v-icon v-else size="16" class="pr-1">fa-list</v-icon>

        </v-btn>
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
     

    
        <!-- PC hidden-xs-only -->
        <v-data-table :headers="headers" :items="dataset" :loading="tableloading" :items-per-page="9999999999"
          hide-default-footer class="packhai-border-table hidden-xs-only">
          <template v-for="(header, index) in header" v-slot:[`header.${header.value}`]="{ header }">
            <v-tooltip :key="index" v-slot:activator="{ on }">
              <span>{{ header.text }}</span>
              <span
                v-if="header.value == 'no' || header.value == 'name' || header.value == 'prop1' || header.value == 'unitPrice' || header.value == 'productCode' || header.value == 'cfCode' || header.value == 'sku' || header.value == 'barcode'">
                <v-icon v-if="header.icon == 0" @click="OrderByColumn(index)" size="16" class="icon-white">
                  mdi-arrow-up
                </v-icon>
                <v-icon v-else-if="header.icon == 1" @click="OrderByColumn(index)" size="16">
                  mdi-arrow-up
                </v-icon>
                <v-icon v-else-if="header.icon == 2" @click="OrderByColumn(index)" size="16">
                  mdi-arrow-down
                </v-icon>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.no="{ item }">
            {{ formatMoney( item.no) }}
          </template>
          <template v-slot:item.photoLink="{ item }">
            <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" class="picsize mt-1">
            </a>
          </template>
          <template v-slot:item.name="{ item }">
            <span v-if="ViewType==0"  >
              {{ item.name }}
            </span>
            <span v-else-if="ViewType==1" class="fn-14" >
             <strong> {{ item.name }}</strong> 
            </span>
            <div v-if="ViewType==1">
              <v-row  v-if="item.barcode">
                <v-col cols="12" align="left" class="">
                  <span v-if="item.barcode"> barcode : </span>
                  <span v-if="item.barcode"  style="color: gray;">{{ item.barcode}}  </span>
                  <span v-if="item.productCode && item.barcode">, รหัสสินค้า : </span>
                  <span v-else-if="item.productCode"> รหัสสินค้า : </span>
                  <span v-if="item.productCode"  style="color: gray;">{{ item.productCode}}  </span>
                </v-col>
              </v-row>
            <v-row>
              <v-col cols="12" align="left"  v-if="item.prop1Description">
                    <span>ลักษณะ : </span>
                    <span  style="color: gray;">
                       {{ item.prop1Description + (item.prop2Description? ", "+item.prop2Description:"")}}   
                    </span>
              </v-col>
            </v-row>

          
            
            <v-row v-if="item.brand">
              <v-col cols="12" align="left" class="">
                <span  >Brand : </span>
                <span  style="color: gray;">  {{  item.brand }}</span>
              </v-col>
            </v-row>
           
            </div>
            <v-row v-if="item.isProductSet">
                  <v-col cols="12" align="left"  >
                    <span  class="fn-13" style="color: gray;" >
                      <v-icon size="22" style="color: gray;" disabled >mdi-package-variant-closed-plus</v-icon>
                      เซ็ตสินค้า
                    </span>
                  </v-col>
                </v-row>
          </template>
          <template v-slot:item.productCode="{ item }">
            <span class="fn-13">
              {{ item.productCode }}
            </span>
          </template>
          <template v-slot:item.prop1="{ item }">
            <span class="fn-13">
              {{ (item.prop1Description != null && item.prop1Description != "" ? item.prop1Description : "") +
                (item.prop2Description != null && item.prop2Description != "" ? " , " + item.prop2Description : "") }}
            </span>
          </template>
          <template v-slot:item.barcode="{ item }">
            <span class="fn-13">
              {{ item.barcode }}
            </span>
          </template>
          <template v-slot:item.sku="{ item }">
            <span class="fn-13">
              {{ item.sku }}
            </span>
          </template>
          <template v-slot:item.unitPrice="{ item }">
            <span class="fn-13">
              {{ format_price(item.unitPrice) }}
            </span>
          </template>
          <template v-slot:item.isProductSet="{ item }">
            <span v-if="item.isProductSet" class="fn-13">
              <v-icon size="22" color="orange darken-3">mdi mdi-pin</v-icon>
            </span>
          </template>
          <template v-slot:item.edit="{ item }">
            <a @click="UpdateStock(item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
          </template>
          <template v-slot:item.delete="{ item }">
            <a @click="DeleteStock(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
          </template>
        </v-data-table>

        <!-- Mobile hidden-sm-and-up -->
        <v-data-iterator :items="dataset" :items-per-page="9999999999" hide-default-header hide-default-footer
          class="hidden-sm-and-up">
          <template v-slot:default="props">
            <v-row class="ma-0">
              <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                <v-card>
                  <v-card-title class="subheading font-weight-bold pa-0">
                    <div class="px-0 v-list-item">
                      <a v-if="item.photoLink != null && item.photoLink != ''" @click="OpenPhoto(item.photoLink)"
                        class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                        <img :src="item.photoLink" class="picsize">
                      </a>
                      <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                        style="height: 90px; min-width: 90px; width: 90px;">
                        <span class="bold-color-gray-12"> No Picture </span>
                      </a>
                      <div class="v-list-item__content pa-2 pl-0">

                        <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>
                              ชื่อ
                            </div>
                            <div style="line-height: 1rem !important;">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on" class="cut-text">
                                    <span v-if="item.name != null">: {{ item.name }}</span>
                                  </div>
                                </template>
                                <span v-if="item.name != null">{{ item.name }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="v-markdown subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ลักษณะ
                              </div>
                              <div class="cut-text">
                                : {{ (item.prop1Description != null && item.prop1Description != "" ? item.prop1Description : "") +
                                  (item.prop2Description != null && item.prop2Description != "" ? ", "+item.prop2Description : "") }}
                              </div>
                            </div>

                            <br />
                            <div>
                              <div>
                                รหัสสินค้า
                              </div>
                              <div class="cut-text">
                                : {{ item.productCode }}
                              </div>
                            </div>

                            <br />
                            <div>
                              <div>
                                SKU
                              </div>
                              <div class="cut-text">
                                : {{ item.sku }}
                              </div>
                            </div>

                            <br />
                            <div>
                              <div>
                                Barcode
                              </div>
                              <div class="cut-text">
                                : {{ item.barcode }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </v-card-title>
                  <v-divider />

                  <div class="px-0 v-list-item">
                    <div class="v-list-item__content pa-2 pt-0">
                      <div>
                        <div class="v-markdown subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>
                              ร้านค้า
                            </div>
                            <div class="cut-text">
                              : {{ item.shopName }}
                            </div>
                          </div>

                          <br />
                          <div>
                            <div>
                              ราคาขาย
                            </div>
                            <div class="cut-text">
                              : {{ formatMoney(item.unitPrice) }}
                            </div>
                          </div>

                          <br />
                          <div>
                            <div>
                              รหัส CF
                            </div>
                            <div class="cut-text">
                              : {{ item.cfCode }}
                            </div>
                          </div>

                          <br />
                          <div>
                            <div>
                              หมายเหตุ
                            </div>
                            <div class="cut-text">
                              : {{ item.remark }}
                            </div>
                          </div>

                          <br />
                          <div>
                            <div>
                              เช็ตสินค้า
                            </div>
                            <div>
                              :
                              <span v-if="item.isProductSet" class="fn-13">
                                <v-icon size="22" color="orange darken-3">mdi mdi-pin</v-icon>
                              </span>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="pa-2 pt-1">
                      <div>
                        <div class="v-markdown subtitle-1 packhai-showproduct-2">
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br />
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br />
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br />
                          <div>
                            <div class="pr-1">
                              <v-spacer />
                              <a @click="UpdateStock(item)"><v-icon size="15"
                                  color="orange darken-3">fa-edit</v-icon></a>
                            </div>
                          </div>

                          <br />
                          <div>
                            <div class="pr-1">
                              <v-spacer />
                              <a @click="DeleteMarketplace(item)"><v-icon size="15"
                                  color="red darken-3">fa-trash-alt</v-icon></a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

    

    <!-- เปลี่ยนหน้า -->
    <v-row v-if="pageLength > 1 && dataset.length > 0" class="text-center pa-2 ma-0" >
      <v-col cols="2" lg="1"> </v-col>
      <v-col cols="8" lg="10" @click="ChangePage()">
        <v-pagination v-model="page" :length="pageLength" :total-visible="pageTotalVisible" color="info"></v-pagination>
      </v-col>
      <v-col cols="2" class="text-right" lg="1">
        <v-select v-model="limits" label="จำนวนแสดง" :items="[10, 20, 50, 100, 500, 1000]" @change="LimitChange()"
          outlined></v-select>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Loading from '@/website/components/Loading'
import { stockService_dotnet, shopService_dotnet } from '@/website/global'
import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
import { formatMoney, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules,UpdateViewTypePage,GetViewTypePage } from '@/website/global_function'
export default {
  components: {
    Loading
  },
  data: () => ({
    // page
    header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
    BranchID:parseInt(localStorage.getItem('Branch_BranchID')),
    page_loading: true,
    loading: false,
    language: 'th',
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: '',
    ViewType:0,
    // ค้นหา
    shopList: [],
    search: {
      shopList: [{ id: 0, name: "--ทั้งหมด--" }],
      shop: { id: 0, name: "--ทั้งหมด--" },
      "name": null,
      "productCode": null,
      "barcode": null,
      "sku": null,
      "isProductSet": 2,
      "isProductSetTemp": { "id": 2, "name": "--ทั้งหมด--" },
      "isProductSetList": [
        { "id": 2, "name": "--ทั้งหมด--" },
        { "id": 1, "name": "ใช่" },
        { "id": 0, "name": "ไม่ใช่" }
      ],
      orderbyName: 'no',
      isAscending: false,
    },
    ExcelFileName:'',
    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [
      { label: "No", field: "no", width: 10 },
      { label: "ชื่อ", field: "name", width: '50px' },
      { label: "ลักษณะ", field: "prop1", width: 20 },
      { label: "ราคาขาย", field: "unitPrice", width: 15 },
      { label: "รหัสสินค้า", field: "productCode", width: 15 },
      { label: "รหัส CF", field: "cfCode", width: 15 },
      { label: "Barcode", field: "barcode", width: 15 },
      { label: "SKU", field: "sku", width: 15 },
      { label: "ร้านค้า", field: "shopName", width: '50px' },
      { label: "หมายเหตุ", field: "remark", width: 20 },
      { label: "เช็ตสินค้า", field: "isProductSet", width: 10 },
    ],

    // tab
  
    // แสดงตาราง
    tableloading: false,
    total: 0,
    dataset: [],
    header: [
      { text: 'No', align: 'center', sortable: false, value: 'no', icon: 0, width: '70px' },
      { text: 'แก้ไข', align: 'center', sortable: false, value: 'edit', icon: 0, width: '60px' },
      { text: 'ลบ', align: 'center', sortable: false, value: 'delete', icon: 0, width: '60px' },
      { text: 'รูป', align: 'center', sortable: false, value: 'photoLink', icon: 0, width: '80px' },
      { text: 'ชื่อ', align: 'left', sortable: false, value: 'name', icon: 0 },
      { text: 'ลักษณะ', align: 'center', sortable: false, value: 'prop1', icon: 0 },
      
      { text: 'รหัสสินค้า', align: 'center', sortable: false, value: 'productCode', icon: 0 },
      { text: 'รหัส CF', align: 'center', sortable: false, value: 'cfCode', icon: 0 },
      { text: 'Barcode', align: 'center', sortable: false, value: 'barcode', icon: 0 },
      { text: 'SKU', align: 'center', sortable: false, value: 'sku', icon: 0 },
    
      { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName', icon: 0 , width: '180px' }, 
    
    ],

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 10,
    offsets: 0,

  }),
  watch: {
  },
  async created() {
    this.ViewType = await this.GetViewTypePage('my-stock');
   
    this.formatHeader(); 
    this.headers[0].icon=1;
    this.GetShop()
    this.Load_Data(this.limits, this.offsets, this.search, 1 ) 
    this.page_loading = false
  },
  methods: {
    async GetShop() {
      let response = await axios.post(shopService_dotnet + 'Shop/get-shop-list', {
        "shopType": 1,
        "branchId": localStorage.getItem('Branch_BranchID'),
      }, { headers: this.header_token })
      if (response.status == 200) {
        this.shopList = response.data.result
        if (this.shopList.length > 0) {
          for (var j in this.shopList) {
            this.search.shopList.push({
              id: this.shopList[j].shop.id,
              name: this.shopList[j].shop.name,
            })
          }
        }
      }
    },
    formatHeader(){
      let remove = ["Barcode","ลักษณะ","รหัสสินค้า","รหัส CF","Brand"]
      this.headers = [...this.header]

      if( this.ViewType==1)
      {
        for(var item in remove)
        {
          let index =  this.headers.findIndex(x => x.text === remove[item])

          if(index != -1)
          {
            this.headers.splice(index, 1);
          }
        }

      }

    },
    async ChangTab(tab) {
      this.reSetSort()
      this.Load_Data(this.limits, 0, this.search, 1, tab)
      this.Load_Count(this.limits, 0, this.search, tab, false)
      this.reSetIconHeader()
    },
    reSetSort() {
      this.search.orderbyName = 'id'
      this.search.isAscending = false
    },
    reSetIconHeader() {
      for (var i in this.header) {
        this.header[i].icon = 0
      }
    },
    async SearchStock() { 
      this.Load_Data(this.limits, 0, this.search, 1)
   
    },
    async LimitChange() {
      await this.Load_Data(this.limits, 0, this.search, 1);
    },
    async Load_Data(limits, offsets, search, page) {
      search.isProductSet = search.isProductSetTemp.id
      var searchTemp = {}
      searchTemp["name"] = search.name
      searchTemp["productCode"] = search.productCode
      searchTemp["barcode"] = search.barcode
      searchTemp["sku"] = search.sku
      searchTemp["isProductSet"] = (search.isProductSet ==2?null :  search.isProductSet)
      searchTemp["orderbyName"] = search.orderbyName
      searchTemp["isAscending"] = search.isAscending

      this.loading = true;
      let response = await axios.post(stockService_dotnet + 'Stock/get-stock-productMasterItem-list', {
        "branchId": this.BranchID,
        "shopId": search.shop.id == 0 ? null : search.shop.id,
        "keyword": searchTemp,
        "isNeedProductCount":true,
        "skip": offsets,
        "take": limits,
      }, { headers: this.header_token })
      this.tableloading = false
      if (response.status == 200) {
        this.loading = false 
        this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
        this.page = page
        this.pageTemp = page
        this.offsets = offsets
        this.dataset = []
        this.dataset = response.data.resultData
        this.total = response.data.resultCount;
      } else {
        this.loading = false
        this.AlertError()
      }
    },
  
    async changeFormat_DataTabel(){
      if(this.ViewType==1){
        this.ViewType=0;
      }else{
        this.ViewType=1;
      }
     
      this.UpdateViewTypePage("my-stock",this.ViewType); // ยิงอัพเดทสถานะบันทึกไว้

      this.formatHeader();
    },
    
    async ExportExcel() {
      this.datasetExcel = []
      this.loadingExcel = 0
      Swal.fire({ 
        icon: 'warning',
        text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          const d = new Date();
          let dateExported = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDate().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
          this.ExcelFileName="ข้อมูลสินค้า_"+dateExported;
          this.loadingExcel =1;
          var LimitsForExcel=50;
          var offsets = 0
          var data = await this.GetExportExcelProduct(LimitsForExcel, offsets,true)
          for (var j in data.resultData) {
            this.datasetExcel.push(data.resultData[j])
          }
          var total=data.resultCount;

          this.loadingExcel = 1 
          var round = parseInt(total / LimitsForExcel)

          if (LimitsForExcel * round < total) {
            round = round + 1
          }
          offsets = offsets+LimitsForExcel;
          this.loadingExcel = parseInt(parseFloat(1) / parseFloat(round) * parseFloat(100))
          let index = this.datasetExcel[0].no;
          var isDesc=true;
          if(index==1){
            isDesc=false;
            index = index+LimitsForExcel;
          }else{
            index = index -LimitsForExcel;
          }
          for (var i = 2; i <= round; i++) {

            data = await this.GetExportExcelProduct(LimitsForExcel, offsets,false)
            for (var k in data.resultData) {
              if(isDesc){
                data.resultData[k].no =index;
                index--;
              }else{
                data.resultData[k].no =index;
                index++;
              }
              this.datasetExcel.push(data.resultData[k])
            }
            this.loadingExcel = parseInt(parseFloat(i) / parseFloat(round) * parseFloat(100))
            offsets = offsets + LimitsForExcel
          }
          
          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100
          document.getElementById("GetExcel").click();
        }
      })
    },
    async GetExportExcelProduct(limits, offsets,isNeedProductCount) {
      try {
        var search = this.search
        search.isProductSet = search.isProductSetTemp.id
        var searchTemp = {}
       
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["isProductSet"] = search.isProductSet == 2 ? null : search.isProductSet
        searchTemp["orderbyName"] = search.orderbyName
        searchTemp["isAscending"] = search.isAscending
        let stockList = await axios.post(stockService_dotnet + 'Stock/get-stock-productMasterItem-list', {
          "branchId":this.BranchID,
          "shopId": this.search.shop.id == 0 ? null : this.search.shop.id,
          "keyword": searchTemp,
          "isNeedProductCount":isNeedProductCount,
          "skip": offsets,
          "take": limits,
        }, { headers: this.header_token })
        if (stockList.status == 200) {
          return stockList.data;
        } else {
          return []
        }
      }

      catch (e) {
        console.log('error');
      }
    },
    UpdateStock(item) {
      var data = {
        "productMasterId": null,
        "productMasterItemId": null,
        "isProductSet": 0,
        "part_return": "/my-product",
      }
      if (item != null) {
        data.productMasterId = item.productMasterId
        data.productMasterItemId = item.id
        data.isProductSet = item.isProductSet ? 1 : 0
        localStorage.setItem('my-product-search', JSON.stringify(this.search))
        localStorage.setItem('my-product-limits', this.limits)
        localStorage.setItem('my-product-offsets', this.offsets)
        localStorage.setItem('my-product-page', this.page) 
      } else {
        localStorage.removeItem("my-product-search");
        localStorage.removeItem("my-product-limits");
        localStorage.removeItem("my-product-offsets");
        localStorage.removeItem("my-product-page"); 
      }
      localStorage.setItem('data', JSON.stringify(data))
      window.open("/my-product/update");
      // this.$router.push("/my-product/update")
    },
    async ChangePage() {
       
      
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = (this.page * this.limits) - this.limits
        this.Load_Data(this.limits, offsets, this.search, this.page )
        this.pageTemp = this.page
      }
    },
    async OrderByColumn(index) {
      this.tableloading = true
      this.search.orderbyName = this.header[index].value
      this.search.isAscending = this.header[index].icon == 0 ? false : (this.header[index].icon == 1 ? true : false)
      for (var i in this.header) {
        if (!(parseInt(index) == parseInt(i))) {
          this.header[i].icon = 0
        }
      }
      this.header[index].icon = this.header[index].icon == 0 ? 1 : (this.header[index].icon == 1 ? 2 : 1)
      await this.Load_Data(this.limits, this.offsets, this.search, this.page)
      this.tableloading = false
    },
    async DeleteStock(item) {
      Swal.fire({
        position: "center",
        icon: 'question',
        title: 'ลบ ใช่หรือไม่?',
        text: item.name + ' ' + (item.prop1Description != null && item.prop1Description != "" ? item.prop1Description : "") + (item.prop2Description != null && item.prop2Description != "" ? " , " + item.prop2Description : ""),
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          this.loading = true
          let response = null
          if (item.isProductSet) {
            response = await axios.post(stockService_dotnet + 'Stock/delete-productset', {
              "productMasterId": item.productMasterId,
            }, { headers: this.header_token })
          } else {
            response = await axios.post(stockService_dotnet + 'Stock/delete-stock', {
              "productMasterItemId": item.id,
            }, { headers: this.header_token })
          }
          if (response.status == 200) {
            if (response.data.status == "success") {
              await this.SearchStock()
              this.loading = false
              this.AlertSuccess()
            } else {
              this.loading = false
              Swal.fire({
                icon: 'error',
                text: response.data.message[this.language]
              })
            }

          } else {
            this.loading = false
            Swal.fire({
              icon: 'error',
              text: response.data.message[this.language]
            })
          }
        }
      })
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo
      this.ShowPhoto = true
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    formatMoney,
    format_price,
    isNumberWithDot,
    isNumberWNoDot,
    DateNowNotNull,
    Trim_value,
    Trim_rules,
    AlertSuccess,
    AlertWarning,
    AlertError,
    UpdateViewTypePage,
    GetViewTypePage
  }
}
</script>

<style scoped>
>>>.v-select__slot {
  height: 40px;
}
</style>
