<template>
  <div v-if="page_loading">
    <Loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <Loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ออเดอร์
        <v-icon>mdi-chevron-right</v-icon>
        ตรวจสอบออเดอร์
      </v-card-title>
    </v-card>
    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pl-10">

          <v-row class="pb-2">
            <v-col class="pb-1" cols="12" md="3" lg="2"> เลขออเดอร์ : </v-col>
            <v-col class="pb-1" cols="12" md="4">
              <v-text-field
                :value="orderId"
                @change="value => orderId = Trim_value(value)"
                ref="orderId"
                :rules="rulesOrderId"
                @keyup.enter="SearchOrder()"
                @keypress="isEnglishlanguage($event)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="6">
              <v-btn :elevation="1" class="pa-2" color="primary" dark @click="SearchOrder()">
                <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
              </v-btn>
              <v-btn :elevation="1" class="pa-2 ml-2" outlined color="primary"  @click="ResetSearch()">
                <v-icon size="14" class="pr-1">fa-solid fa-rotate</v-icon> รีเช็ต
              </v-btn>
            </v-col>
          </v-row>

      </v-card-text>
    </v-card>

    <v-alert v-if="error" dense border="left" type="warning"> ไม่พบข้อมูล </v-alert>

    <OrderDetail v-if="letgoOrderId!=null && !error" :orderId="letgoOrderId" :orderToken="null" />

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import OrderDetail from '@/website/components/order-detail'
  import { branchService_dotnet, orderService_dotnet } from '@/website/global'
  import { AlertError } from '@/website/global_alert'
  import { Trim_value, isEnglishlanguage, ChangeOrderId, length60 } from '@/website/global_function'
  export default {
    components: {
      Loading,
      OrderDetail,
    },
    data: vm => ({
      // loading
      page_loading: true,
      loading: false,

      // order-detail
      letgoOrderId: null,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      orderId: null,
      rulesOrderId: [],
      error: false,
    }),
    watch: {
    },
    async created() {
      this.page_loading = false
    },
    methods: {
      async SearchOrder(){
        await this.rulesSearchCheck()
        var orderId = this.ChangeOrderId(this.orderId)
        this.letgoOrderId = null
        let response = await axios.post(orderService_dotnet+'Order/check-orderid-from-channel', {
          branchId: localStorage.getItem('Branch_BranchID'),
          orderId: orderId,
        }, {headers: header_token})
        this.error = false
        if (response.status==200){
          var status = response.data
          if(status=="fail"){ this.error = true }
          else{ this.letgoOrderId = orderId }
        } else {
          this.AlertError()
        }
      },
      ResetSearch(){
        this.rulesSearch()
        this.orderId = null
        this.error = false
        this.letgoOrderId = null
      },
      rulesSearchCheck(){
        this.rulesOrderId = [ v => !!v || 'กรุณาระบุ', ]
      },
      rulesSearch(){
        this.rulesOrderId = []
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      Trim_value,
      isEnglishlanguage,
      ChangeOrderId,
      length60,
      AlertError,
    }
  }
</script>
