<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        Location
      </v-card-title>
    </v-card>


    <v-card>
      <v-card-title class="pa-0">
        <v-tabs v-model="tab">
          <v-tab href="#tab-1"> Location</v-tab>
          <v-tab href="#tab-2"> ประเภทการจัดเก็บ </v-tab>
          <v-tab href="#tab-3"> โซนพื้นที่ </v-tab>
        </v-tabs>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-title>
                <v-row class="mb-3">
                  <v-col cols="12" md="6" lg="6" xl="6">
                    ข้อมูล Location
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" align="right">
                    <v-btn dark color="primary" @click="open_dialog_location()" class="fn-14"><v-icon
                        left>fa-plus</v-icon>เพิ่ม location</v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-data-table :headers="location_header" :items="location_list" item-key="id"
                      class="elevation-0 packhai-border-table" :mobile-breakpoint="0" :items-per-page="10000000"
                      hide-default-footer>
                      <template v-slot:item.num="{ item }">
                        {{ gen_num_data_table(location_list.indexOf(item), location_sum, offsets_location) }}
                      </template>
                      <template v-slot:item.stockAreaID="{ item }">
                        {{ area_to_name(item.stockAreaID) }}
                      </template>
                      <template v-slot:item.stockLocationTypeID="{ item }">
                        {{ type_to_name(item.stockLocationTypeID) }}
                      </template>
                      <template v-slot:item.isActive="{ item }">
                        <span v-if="item.isActive == true" style="color: green">Active</span>
                        <span v-else>Not Active</span>
                      </template>
                      <template v-slot:item.edit="{ item }">
                        <v-btn icon dark color="primary" class="my-1"
                          @click="manage_location_manage_alert('edit', item)"><v-icon
                            class="ma-1">mdi-pencil</v-icon></v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-btn icon dark color="danger" class="my-1"><v-icon class="ma-1"
                            @click="manage_location_manage_alert('delete', item)">mdi-delete</v-icon></v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mx-0 my-5" v-if="location_list.length == 0">
                  <v-col cols="12" align="center">
                    <span>ไม่มีข้อมูล !!</span>
                  </v-col>
                </v-row>
                <v-row class="mx-0" v-if="location_list.length > 0">
                  <v-col cols="12" align="center">
                    <div class="text-center" @click="changePage_location()">
                      <v-pagination v-model="page_location" :length="pageLength_location" :total-visible="7"
                        color="primary"></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>


          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-title>
                <v-row class="mb-3">
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <span>จัดการ ประเภท</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" align="right">
                    <v-btn dark color="primary" @click="open_dialog_type()" class="fn-14"><v-icon
                        left>fa-plus</v-icon>เพิ่มประเภท</v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8" lg="8" xl="6">
                    <v-data-table :headers="location_type_header" :items="location_type_list" item-key="id"
                      class="elevation-0 packhai-border-table" :mobile-breakpoint="0" :items-per-page="10000000"
                      hide-default-footer>
                      <template v-slot:item.num="{ item }">
                        {{ gen_num_data_table(location_type_list.indexOf(item), location_type_sum, offsets_type) }}
                      </template>
                      <template v-slot:item.isActive="{ item }">
                        <span v-if="item.isActive == true" style="color: green">Active</span>
                        <span v-else>Not Active</span>
                      </template>
                      <template v-slot:item.edit="{ item }">
                        <v-btn icon dark color="primary" class="my-1"
                          @click="manage_type_manage_alert('edit', item)"><v-icon
                            class="ma-1">mdi-pencil</v-icon></v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-btn icon dark color="danger" class="my-1"><v-icon class="ma-1"
                            @click="manage_type_manage_alert('delete', item)">mdi-delete</v-icon></v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mx-0 my-5" v-if="location_type_list.length == 0">
                  <v-col cols="12" md="8" lg="8" xl="6" align="center">
                    <span>ไม่มีข้อมูล !!</span>
                  </v-col>
                </v-row>
                <v-row class="mx-0" v-if="location_type_list.length > 0">
                  <v-col cols="12" md="8" lg="8" xl="6" align="center">
                    <div class="text-center" @click="changePage_type()">
                      <v-pagination v-model="page_type" :length="pageLength_type" :total-visible="7"
                        color="primary"></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-3">
            <v-card flat>
              <v-card-title>
                <v-row class="mb-3">
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <span>จัดการ โซนพื้นที่</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" align="right">
                    <v-btn dark color="primary" @click="open_dialog_area()" class="fn-14"><v-icon
                        left>fa-plus</v-icon>เพิ่มโซน</v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8" lg="8" xl="6">
                    <v-data-table :headers="location_area_header" :items="location_area_list" item-key="id"
                      class="elevation-0 packhai-border-table" :mobile-breakpoint="0" :items-per-page="10000000"
                      hide-default-footer>
                      <template v-slot:item.num="{ item }">
                        {{ gen_num_data_table(location_area_list.indexOf(item), location_area_sum, offsets_area) }}
                      </template>
                      <template v-slot:item.isActive="{ item }">
                        <span v-if="item.isActive == true" style="color: green">Active</span>
                        <span v-else>Not Active</span>
                      </template>
                      <template v-slot:item.edit="{ item }">
                        <v-btn icon dark color="primary" class="my-1"
                          @click="manage_area_manage_alert('edit', item)"><v-icon
                            class="ma-1">mdi-pencil</v-icon></v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-btn icon dark color="danger" class="my-1"><v-icon class="ma-1"
                            @click="manage_area_manage_alert('delete', item)">mdi-delete</v-icon></v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mx-0 my-5" v-if="location_area_list.length == 0">
                  <v-col cols="12" md="8" lg="8" xl="6" align="center">
                    <span>ไม่มีข้อมูล !!</span>
                  </v-col>
                </v-row>
                <v-row class="mx-0" v-if="location_area_list.length > 0">
                  <v-col cols="12" md="8" lg="8" xl="6" align="center">
                    <div class="text-center" @click="changePage_area()">
                      <v-pagination v-model="page_area" :length="pageLength_area" :total-visible="7"
                        color="primary"></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog_location" max-width="600" persistent>
      <v-card elevation="1">
        <v-card-title class="pr-1">
          <span v-if="location.id == 0">เพิ่ม Location</span>
          <span v-if="location.id != 0">แก้ไข Location</span>
          <v-spacer />
          <v-btn icon @click="dialog_location = false, $refs.form.reset()"><v-icon
              class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-7 pb-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>Location</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-text-field v-model="location.name" placeholder="กรุณากรอก" ref="location_name" :rules="nameRules"
                  outlined dense hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>กว้าง</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-text-field v-model="location.cubic1" outlined dense hide-details type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>ยาว</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-text-field v-model="location.cubic2" outlined dense hide-details type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>สูง</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-text-field v-model="location.cubic3" outlined dense hide-details type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>พื้นที่</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-autocomplete v-model="location.stockAreaID" ref="location_area" :items="area_active_list"
                  :item-text="item => item.name" :item-value="item => item.id" outlined dense
                  hide-details></v-autocomplete>

              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>ประเภท</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-autocomplete v-model="location.stockLocationTypeID" ref="location_area" :items="type_active_list"
                  :item-text="item => item.name" :item-value="item => item.id" outlined dense
                  hide-details></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="py-1">
              <v-col cols="12" md="5" lg="4" xl="4">
                <strong>สถานะ</strong> :
              </v-col>
              <v-col cols="12" md="7" lg="8" xl="8" align="right">
                <v-select :disabled="location.id == 0" v-model="location.isActive"
                  :items="[{ 'name': 'เปิดใช้งาน', 'id': true }, { 'name': 'ปิดใช้งาน', 'id': false }]"
                  :item-text="item => item.name" :item-value="item => item.id" outlined dense hide-details>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn v-show="location.id != 0" dark color="primary" class="my-1" @click="manage_location('edit')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
          <v-btn v-show="location.id == 0" dark color="primary" class="my-1" @click="manage_location('add')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_type" max-width="600" persistent>
      <v-card elevation="1">
        <v-card-title class="pr-1">
          <span v-if="location_type.id == 0">เพิ่ม Type</span>
          <span v-if="location_type.id != 0">แก้ไข Type</span>
          <v-spacer />
          <v-btn icon @click="dialog_type = false, location_type.id = 0, location_type.name = null"><v-icon
              class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-7 pb-0">
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>Type</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field v-model="location_type.name" ref="location_type_name" placeholder="กรุณากรอก" outlined dense
                hide-details></v-text-field>
            </v-col>
          </v-row>

          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>สถานะ</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-select v-model="location_type.isActive"
                :items="[{ 'name': 'เปิดใช้งาน', 'id': true }, { 'name': 'ปิดใช้งาน', 'id': false }]"
                :item-text="item => item.name" :item-value="item => item.id" :disabled="location_type.id == 0" outlined
                dense hide-details>
              </v-select>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn v-show="location_type.id != 0" dark color="primary" class="my-1" @click="manage_type('edit')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
          <v-btn v-show="location_type.id == 0" dark color="primary" class="my-1" @click="manage_type('add')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_area" max-width="600" persistent>
      <v-card elevation="1">
        <v-card-title class="pr-1">
          <span v-if="location_area.id == 0">เพิ่ม Area</span>
          <span v-if="location_area.id != 0">แก้ไข Area</span>
          <v-spacer />
          <v-btn icon @click="dialog_area = false, location_area.id = 0, location_area.name = null"><v-icon
              class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-7 pb-0">
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>Area</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field v-model="location_area.name" ref="location_area_name" placeholder="กรุณากรอก" outlined dense
                hide-details></v-text-field>
            </v-col>
          </v-row>

          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>สถานะ</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-select v-model="location_area.isActive"
                :items="[{ 'name': 'เปิดใช้งาน', 'id': true }, { 'name': 'ปิดใช้งาน', 'id': false }]"
                :item-text="item => item.name" :item-value="item => item.id" :disabled="location_area.id == 0" outlined
                dense hide-details>
              </v-select>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn v-show="location_area.id != 0" dark color="primary" class="my-1" @click="manage_area('edit')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
          <v-btn v-show="location_area.id == 0" dark color="primary" class="my-1" @click="manage_area('add')"><v-icon
              class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import { utils, writeFileXLSX } from 'xlsx';
import Swal from 'sweetalert2'
import { header_token } from '@/website/token'
import Loading from '@/website/components/Loading'
import { stockService_dotnet } from '@/website/global'
import { AlertSuccess, AlertWarning } from '@/website/global_alert'
import { formatDatePart, FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type } from '@/website/global_function'
import { log } from 'async'
export default {
  components: {
    Loading
  },
  data: () => ({
    page_loading: true,
    loading: false,

    navigete: [],
    tab: 'tab-1',

    location_type: {
      "id": 0,
      "branchID": 0,
      "name": null,
      "isDelete": false,
      "isActive": true
    },

    location_area: {
      "id": 0,
      "branchID": 0,
      "name": null,
      "isDelete": false,
      "isActive": true
    },

    location: {
      "id": 0,
      "branchID": 0,
      "stockAreaID": null,
      "stockLocationTypeID": null,
      "cubic1": null,
      "cubic2": null,
      "cubic3": null,
      "name": null,
      "isDelete": false,
      "isActive": true
    },

    location_header: [
      { text: "No", value: "num", sortable: false, align: 'center' },
      { text: "Location", value: "name", sortable: false, align: 'left' },
      { text: "พื้นที่", value: "stockAreaID", sortable: false, align: 'left' },
      { text: "ประเภท", value: "stockLocationTypeID", sortable: false, align: 'left' },
      { text: "กว้าง(cm)", value: "cubic1", sortable: false, align: 'right' },
      { text: "ยาว(cm)", value: "cubic2", sortable: false, align: 'right' },
      { text: "สูง(cm)", value: "cubic3", sortable: false, align: 'right' },
      { text: "สถานะ", value: "isActive", sortable: false, align: 'center' },
      { text: "แก้ไข", value: "edit", sortable: false, align: 'center' },
      { text: "ลบ", value: "delete", sortable: false, align: 'center' },
    ],
    location_list: [],
    location_sum: 0,

    location_type_header: [
      { text: "No", value: "num", sortable: false, align: 'center' },
      { text: "ประเภท", value: "name", sortable: false, align: 'left' },
      { text: "สถานะ", value: "isActive", sortable: false, align: 'left' },
      { text: "แก้ไข", value: "edit", sortable: false, align: 'center' },
      { text: "ลบ", value: "delete", sortable: false, align: 'center' },
    ],
    location_type_list: [],
    location_type_sum: 0,


    location_area_header: [
      { text: "No", value: "num", sortable: false, align: 'center' },
      { text: "ประเภท", value: "name", sortable: false, align: 'left' },
      { text: "สถานะ", value: "isActive", sortable: false, align: 'left' },
      { text: "แก้ไข", value: "edit", sortable: false, align: 'center' },
      { text: "ลบ", value: "delete", sortable: false, align: 'center' },
    ],
    location_area_list: [],
    location_area_sum: 0,

    dialog_location: false,
    dialog_type: false,
    dialog_area: false,

    type_active_list: [],
    area_active_list: [],

    take_location: 0,
    take_type: 0,
    take_area: 0,


    // paginations location
    isPage_location: 0,
    page_location: 1,
    page_tmp_location: null,
    limits_location: 50,
    offsets_location: 0,
    pageLength_location: null,


    // paginations type
    isPage_type: 0,
    page_type: 1,
    page_tmp_type: null,
    limits_type: 50,
    offsets_type: 0,
    pageLength_type: null,

    // paginations area
    isPage_area: 0,
    page_area: 1,
    page_tmp_area: null,
    limits_area: 50,
    offsets_area: 0,
    pageLength_area: null,


    // Rules
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
      // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],

    typeRules: [
      v => !!v || 'Type is required',
    ],

    areaRules: [
      v => !!v || 'Type is required',
    ],

    excel_row_header1: [[
      "No",
      "Location",
      "ประเภท",
      "โซน",
      "กว้าง",
      "ยาว",
      "สูง"
    ]],
    excel_row_header2: [[
      "No",
      "ประเภท"
    ]],
    excel_row_header3: [[
      "No",
      "โซน"
    ]],

  }),
  async created() {
    this.navigete = JSON.parse(localStorage.getItem('navigete'))
    this.load_data_location()
    this.load_area_and_type()
    this.load_data_type()
    this.load_data_area()

    // this.getInvoice()
    this.page_loading = false
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async load_data_location() {
      let res1 = await axios.post(stockService_dotnet + 'Stock/load-data-manage-location', {
        "branchID": localStorage.getItem('Branch_BranchID'),
        "limit": this.limits_location,
        "skip": this.offsets_location
      },
        { headers: header_token })

      this.location_list = res1.data.data_location
      this.location_sum = res1.data.sum_location
      this.getpagelength_location(res1.data.sum_location)
      // this.location_list_tmp = JSON.parse(JSON.stringify(this.location_list));

    },

    async load_data_type() {
      let res1 = await axios.post(stockService_dotnet + 'Stock/load-data-manage-type', {
        "branchID": localStorage.getItem('Branch_BranchID'),
        "limit": this.limits_type,
        "skip": this.offsets_type
      },
        { headers: header_token })

      this.location_type_list = res1.data.data_location_type
      this.location_type_sum = res1.data.sum_type
      this.getpagelength_type(res1.data.sum_type)
      // this.location_type_list_tmp = JSON.parse(JSON.stringify(this.location_type_list));


    },

    async load_area_and_type() {
      //  area
      let res1 = await axios.post(stockService_dotnet + 'Stock/load-data-manage-area', {
        "branchID": localStorage.getItem('Branch_BranchID'),
        "limit": 1000000,
        "skip": 0
      },
        { headers: header_token })
      let data_area = res1.data.data_area
      if (data_area != null) {
        this.area_active_list = [{
          "id": 0,
          "branchID": 0,
          "name": "-- กรุณาเลือก --",
          "isDelete": false,
          "isActive": true
        }]
        for (var tt12 of data_area) {
          if (tt12.isActive == true) {
            this.area_active_list.push(tt12)
          }
        }
      }


      // type
      let res2 = await axios.post(stockService_dotnet + 'Stock/load-data-manage-type', {
        "branchID": localStorage.getItem('Branch_BranchID'),
        "limit": 1000000,
        "skip": 0
      },
        { headers: header_token })
      let data_type = res2.data.data_location_type
      if (data_type != null) {
        this.type_active_list = [{
          "id": 0,
          "branchID": 0,
          "name": "-- กรุณาเลือก --",
          "isDelete": false,
          "isActive": true
        }]
        for (var tt1 of data_type) {
          if (tt1.isActive == true) {
            this.type_active_list.push(tt1)
          }
        }
      }


    },

    async load_data_area() {
      let res1 = await axios.post(stockService_dotnet + 'Stock/load-data-manage-area', {
        "branchID": localStorage.getItem('Branch_BranchID'),
        "limit": this.limits_area,
        "skip": this.offsets_area
      },
        { headers: header_token })
      this.location_area_list = res1.data.data_area
      this.location_area_sum = res1.data.sum_area
      this.getpagelength_area(res1.data.sum_area)
      // this.location_area_list_tmp = JSON.parse(JSON.stringify(this.location_area_list));


    },
    async ExportExcel() {



      const ws1 = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws1, this.excel_row_header1);
      utils.sheet_add_json(ws1, this.datasetExcel_new, { origin: 'A2', skipHeader: true });

      const ws2 = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws2, this.excel_row_header2);
      utils.sheet_add_json(ws2, this.datasetExcel_new, { origin: 'A2', skipHeader: true });

      const ws3 = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws3, this.excel_row_header3);
      utils.sheet_add_json(ws3, this.datasetExcel_new, { origin: 'A2', skipHeader: true });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "Location");
      utils.book_append_sheet(wb, ws2, "Type");
      utils.book_append_sheet(wb, ws3, "Area");
      const d = new Date();
      let dateExported = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDate().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
      writeFileXLSX(wb, 'รายงานข้อมูล_Location_' + dateExported + ".xlsx");
    },
    manage_type_manage_alert(type, item) {
      if (type == 'edit') {
        this.dialog_type = true
        this.location_type = JSON.parse(JSON.stringify(item));
      } else if (type == 'delete') {
        this.location_type = JSON.parse(JSON.stringify(item));
        this.location_type.name = null
        this.location_type.isDelete = true
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบประเภท !!',
          text: item.name + ' จะถูกลบออก !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.manage_type('delete')
          }
        })
      }
    },

    async manage_type(type) {

      if (type == 'add') {
        if (this.location_type.name == null || this.location_type.name == '') {
          this.$refs.location_type_name.focus()
          return
        }
        this.location_type.id = 0;
        this.location_type.isDelete = false;
      } else if (type == 'edit') {
        if (this.location_type.name == null || this.location_type.name == '') {
          this.$refs.location_type_name.focus()
          return
        }
        this.location_type.isDelete = false;
      } else if (type == 'delete') {
        this.location_type.isDelete = true;
      }

      this.loading = true
      let res1 = await axios.post(stockService_dotnet + 'Stock/manage-data-location-type-stock', {
        "id": this.location_type.id,
        "branchID": localStorage.getItem('Branch_BranchID'),
        "name": this.location_type.name,
        "isDelete": this.location_type.isDelete,
        "isActive": this.location_type.isActive
      },
        { headers: header_token })

      if (res1.data.status == true) {
        this.loading = false
        this.location_type = {
          "id": 0,
          "branchID": 0,
          "name": null,
          "isDelete": false,
          "isActive": true
        }
        this.dialog_type = false
        if (type == 'add') {
          this.page_type = 1
          this.changePage_type()
        }
        this.load_data_type()
      } else {
        this.loading = false
        Swal.fire({
          position: "top",
          type: "error",
          title: res1.data.message,
          text: res1.data.description,
        })
      }
    },


    manage_area_manage_alert(type, item) {
      if (type == 'edit') {
        this.dialog_area = true
        this.location_area = JSON.parse(JSON.stringify(item));
      } else if (type == 'delete') {
        this.location_area = JSON.parse(JSON.stringify(item));
        this.location_area.name = null
        this.location_area.isDelete = true
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบพื้นที่ !!',
          text: item.name + ' จะถูกลบออก !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.manage_area('delete')
          }
        })
      }
    },

    async manage_area(type) {

      if (type == 'add') {
        if (this.location_area.name == null || this.location_area.name == '') {
          this.$refs.location_area_name.focus()
          return
        }
        this.location_area.id = 0;
        this.location_area.isDelete = false;
      } else if (type == 'edit') {
        if (this.location_area.name == null || this.location_area.name == '') {
          this.$refs.location_area_name.focus()
          return
        }
        this.location_area.isDelete = false;
      } else if (type == 'delete') {
        this.location_area.isDelete = true;
      }

      this.loading = true
      let res1 = await axios.post(stockService_dotnet + 'Stock/manage-data-area-stock', {
        "id": this.location_area.id,
        "branchID": localStorage.getItem('Branch_BranchID'),
        "name": this.location_area.name,
        "isDelete": this.location_area.isDelete,
        "isActive": this.location_area.isActive
      },
        { headers: header_token })

      if (res1.data.status == true) {
        this.loading = false
        this.dialog_area = false
        this.location_area = {
          "id": 0,
          "branchID": 0,
          "name": null,
          "isDelete": false,
          "isActive": true
        }
        if (type == 'add') {
          this.page_area = 1
          this.changePage_area()
        }
        this.load_data_area()
      } else {
        this.loading = false
        Swal.fire({
          position: "top",
          type: "error",
          title: res1.data.message,
          text: res1.data.description,
        })
      }
    },

    open_dialog_location() {
      this.dialog_location = true
      this.load_area_and_type()
      this.location = {
        "id": 0,
        "branchID": 0,
        "stockAreaID": 0,
        "stockLocationTypeID": 0,
        "cubic1": null,
        "cubic2": null,
        "cubic3": null,
        "name": null,
        "isDelete": false,
        "isActive": true
      }
    },

    open_dialog_type() {
      this.dialog_type = true
      this.location_type = {
        "id": 0,
        "branchID": 0,
        "name": null,
        "isDelete": false,
        "isActive": true
      }
    },

    open_dialog_area() {
      this.dialog_area = true
      this.location_area = {
        "id": 0,
        "branchID": 0,
        "name": null,
        "isDelete": false,
        "isActive": true
      }
    },


    manage_location_manage_alert(type, item) {
      if (type == 'edit') {
        this.load_area_and_type()
        this.location = JSON.parse(JSON.stringify(item));
        if (this.location.stockAreaID == null) {
          this.location.stockAreaID = 0;
        }
        if (this.location.stockLocationTypeID == null) {
          this.location.stockLocationTypeID = 0;
        }
        this.dialog_location = true
      } else if (type == 'delete') {
        this.location = JSON.parse(JSON.stringify(item));
        this.location.isDelete = true
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบ location !!',
          text: item.name + ' จะถูกลบออก !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.manage_location('delete')
          }
        })
      }
    },


    async manage_location(type) {
      if (type == 'add') {
        if (this.location.name == null || this.location.name == '') {
          this.$refs.form.validate()
          this.$refs.location_name.focus()
          return
        }
        this.location.id = 0;
        this.location.isDelete = false;
      } else if (type == 'edit') {
        if (this.location.name == null || this.location.name == '') {
          this.$refs.location_name.focus()
          return
        }
        this.location.isDelete = false;
      } else if (type == 'delete') {
        this.location.isDelete = true;
      }
      this.loading = true
      let res1 = await axios.post(stockService_dotnet + 'Stock/manage-data-location-stock', {
        "id": this.location.id,
        "branchID": localStorage.getItem('Branch_BranchID'),
        "stockAreaID": (this.location.stockAreaID == 0 ? null : this.location.stockAreaID),
        "stockLocationTypeID": (this.location.stockLocationTypeID == 0 ? null : this.location.stockLocationTypeID),
        "cubic1": this.location.cubic1,
        "cubic2": this.location.cubic2,
        "cubic3": this.location.cubic3,
        "name": this.location.name,
        "isDelete": this.location.isDelete,
        "isActive": this.location.isActive
      },
        { headers: header_token })

      if (res1.data.status == true) {
        this.loading = false
        this.location = {
          "id": 0,
          "branchID": 0,
          "stockAreaID": null,
          "stockLocationTypeID": null,
          "cubic1": null,
          "cubic2": null,
          "cubic3": null,
          "name": null,
          "isDelete": false,
          "isActive": true
        }
        this.$refs.form.reset()
        this.dialog_location = false
        if (type == 'add') {
          this.page_location = 1
          this.changePage_location()
        }
        this.load_data_location()
      } else {
        this.$refs.form.reset()
        this.loading = false
        Swal.fire({
          position: "top",
          type: "error",
          title: res1.data.message,
          text: res1.data.description,
        })
      }
    },

    type_to_name(id) {
      if (id == null)
        return;

      var notHave = true
      var name_re = ""
      for (var items of this.type_active_list) {
        if (items.id == id) {
          name_re = items.name
          notHave = false
          break;
        }
      }
      if (notHave) {
        return name_re
      } else {
        return name_re
      }
    },

    area_to_name(id) {
      if (id == null) {
        return;
      }

      var notHave = true
      var name_re = ""
      for (var items of this.area_active_list) {
        if (items.id == id) {
          name_re = items.name
          notHave = false
          break;
        }
      }
      if (notHave) {
        return id
      } else {
        return name_re
      }
    },

    getpagelength_location(total_count) {
      var total = 0
      if (total_count != 0) {
        total = total_count
      } else {
        total = 1
      }

      total = parseInt(total)
      this.pageLength_location = Math.ceil(total / this.limits_location)
      this.isPage_location = 0
      this.page_tmp_location = this.page_location
    },

    async changePage_location() {
      this.loading = true
      this.offsets_location = (this.page_location * this.limits_location) - this.limits_location

      if (this.page_location > 1 && this.page_location < this.pageLength_location) {
        if (this.page_tmp_location != this.page_location) {
          await this.load_data_location()
          this.page_tmp_location = this.page_location
        }
      } else if (this.page_location == 1, this.pageLength_location) {
        if (this.page_tmp_location != this.page_location) {
          await this.load_data_location()
          this.page_tmp_location = this.page_location
        }
      }
      this.loading = false
    },

    getpagelength_type(total_count) {
      var total = 0
      if (total_count != 0) {
        total = total_count
      } else {
        total = 1
      }

      total = parseInt(total)
      this.pageLength_type = Math.ceil(total / this.limits_type)
      this.isPage_type = 0
      this.page_tmp_type = this.page_type
    },

    async changePage_type() {
      this.loading = true
      this.offsets_type = (this.page_type * this.limits_type) - this.limits_type

      if (this.page_type > 1 && this.page_type < this.pageLength_type) {
        if (this.page_tmp_type != this.page_type) {
          await this.load_data_type()
          this.page_tmp_type = this.page_type
        }
      } else if (this.page_type == 1, this.pageLength_type) {
        if (this.page_tmp_type != this.page_type) {
          await this.load_data_type()
          this.page_tmp_type = this.page_type
        }
      }
      this.loading = false
    },

    getpagelength_area(total_count) {
      var total = 0
      if (total_count != 0) {
        total = total_count
      } else {
        total = 1
      }

      total = parseInt(total)
      this.pageLength_area = Math.ceil(total / this.limits_area)
      this.isPage_area = 0
      this.page_tmp_area = this.page_area
    },

    async changePage_area() {
      this.loading = true
      this.offsets_area = (this.page_area * this.limits_area) - this.limits_area

      if (this.page_area > 1 && this.page_area < this.pageLength_area) {
        if (this.page_tmp_area != this.page_area) {
          await this.load_data_area()
          this.page_tmp_area = this.page_area
        }
      } else if (this.page_area == 1, this.pageLength_area) {
        if (this.page_tmp_area != this.page_area) {
          await this.load_data_area()
          this.page_tmp_area = this.page_area
        }
      }
      this.loading = false
    },

    gen_num_data_table(index, sum_data, offsets) {
      if (index != null && this.loading == false) {
        var start = null
        start = sum_data - offsets
        return formatMoney(start - index)
      } else {
        return ""
      }
    },


    formatDatePart,
    FormatInvoice,
    FormatOrder,
    formatMoney,
    isNumberWNoDot,
    set_format_date_time,
    format_price,
    format_vat_type,
    AlertSuccess,
    AlertWarning,
  }
}
</script>

<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}

 

 

>>>.v-select__slot {
  height: 40px;
}

@media (max-width: 599px) {
  .mobile-none {
    display: none;
  }

   
}
</style>
