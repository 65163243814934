<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="900px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">แก้ไขล๊อตสินค้า</span>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="dialog = false, editedItem = {}"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>เลขบิล : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ GenIE(editedItem.importExportMasterId) }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>รับเข้า : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ editedItem.quantity }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>คงเหลือ : </strong></v-col>
            <v-col class="col-8 col-md-4">
              {{ editedItem.quantityLeft }}
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>RackNo : </strong></v-col>
            <v-col class="col-12 col-md-4">

              <v-text-field v-if="location_list.length == 0" v-model="editedItem.rackNo" outlined hide-details
                ref="locationRackno">
              </v-text-field>
              <v-combobox v-else v-model="location_selected" :items="location_list" item-text="locationShortName"
                item-value="locationID" return-object @change="(value) => location_selected_changed(value)"
                @keypress="cb_locationKeyPressed($event)">
              </v-combobox>

            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-4 col-md-2 pb-2"><strong>Lot Name : </strong></v-col>
            <v-col class="col-12 col-md-4">
              <v-text-field v-model="editedItem.lotName" outlined hide-details></v-text-field>
            </v-col>
          </v-row>

          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>วันที่ผลิต : </strong></v-col>
            <v-col class="col-12 col-md-4 ">
              <v-menu v-model="editedItem_menuDate.menuStartDate" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.manufactureDate" outlined readonly prepend-inner-icon="mdi-calendar"
                    @blur="editedItem_menuDate.startDate = parseDate(editedItem.manufactureDate)" v-bind="attrs"
                    v-on="on" hide-details clearable @click:clear="dateStart = null"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem_menuDate.startDate"
                  @input="editedItem_menuDate.menuStartDate = false"
                  @change="editedItem.manufactureDate = formatDate(editedItem_menuDate.startDate)"
                  no-title></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class=" py-1" align="center">
            <v-col class="col-12 col-md-2 pb-2"><strong>วันที่หมดอายุ : </strong></v-col>
            <v-col class="col-12 col-md-4">
              <v-menu v-model="editedItem_menuDate.menuEndtDate" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedItem.expirationDate" outlined readonly prepend-inner-icon="mdi-calendar"
                    @blur="editedItem_menuDate.endDate = parseDate(editedItem.expirationDate)" v-bind="attrs" v-on="on"
                    hide-details clearable @click:clear="dateStart = null"></v-text-field>
                </template>
                <v-date-picker v-model="editedItem_menuDate.endDate" @input="editedItem_menuDate.menuEndtDate = false"
                  @change="editedItem.expirationDate = formatDate(editedItem_menuDate.endDate)"
                  no-title></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class=" py-1">
            <v-col class="col-4 col-md-2 pb-2"> <strong>รายละเอียด :</strong></v-col>
            <v-col class="col-12 col-md-6">
              <v-textarea v-model="editedItem.remark" solo />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="text-right">
            <v-col>
              <v-btn :elevation="1" color="primary" class="px-2" @click="SaveEditStockShopItem()">
                บันทึก
              </v-btn>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        ล็อตสินค้า
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <v-icon color="blue" size="24">fa-sliders-h</v-icon>
            <span class="ml-2">ค้นหาข้อมูล</span>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel(search)">
              <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
              <span class="fn-12"> Excel </span>
              <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> คลัง : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-autocomplete
                  v-model="warehouse_selected"
                  placeholder="กรุณาเลือกคลัง"
                  :items="warehouseList_filter"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  return-object
                  hide-details
                  dense
                ></v-autocomplete>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> วันที่ผลิตต่ำกว่า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-menu ref="search_menu_mfg_date" v-model="search.menu_mfg_date" :close-on-content-click="false"
              :return-value.sync="search.mfg_date" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="computed_search_date_mfg" @change="value => computed_search_date_mfg = value"
                  autocomplete="off" label="เลือกวัน" outlined prepend-inner-icon="mdi-calendar" readonly hide-details
                  v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="search.mfg_date"
                @input="search.menu_mfg_date = false, $refs.search_menu_mfg_date.save(search.mfg_date)" no-title
                class="my-0">
                <!-- :allowed-dates="allowedDates" -->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-autocomplete v-model="search.shop" placeholder="กรุณากรอกร้านค้า" :items="search.shopList"
              :item-text="item => item.name" :item-value="item => item.id" outlined required hide-details
              dense></v-autocomplete>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> วันหมดอายุต่ำกว่า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-menu ref="search_menu_expire_date" v-model="search.menu_expire_date" :close-on-content-click="false"
              :return-value.sync="search.expire_date" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="computed_search_expire_date"
                  @change="value => computed_search_expire_date = value" autocomplete="off" label="เลือกวัน" outlined
                  prepend-inner-icon="mdi-calendar" readonly hide-details v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="search.expire_date"
                @input="search.menu_expire_date = false, $refs.search_menu_expire_date.save(search.expire_date)"
                no-title class="my-0">
                <!-- :allowed-dates="allowedDates" -->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> LotName : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.lotName" @change="value => search.lotName = Trim_value(value)" ref="reflotName"
              outlined dense hide-details autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> จำนวนคงเหลือ : </v-col>
          <v-col class="pb-1" cols="12" md="2">
            <v-select v-model="search.quantityCriteriaType" :items="search.quantityType" :item-text="item => item.name"
              :item-value="item => item.id" persistent-hint dense hide-details outlined single-line />
          </v-col>
          <v-col class="pb-1" cols="12" md="2">
            <v-text-field type="number" :value="search.quantity" @change="value => search.quantity = Trim_value(value)"
              @keyup.enter=" page_lotStock = 1, offsets_lotStock = 0,load_data()" ref="refaddFCName" outlined dense hide-details autocomplete="off" />
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ชื่อสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.name" @change="value => search.name = Trim_value(value)"
              @keyup.enter=" page_lotStock = 1, offsets_lotStock = 0,load_data()" ref="refaddFCName" outlined dense hide-details
              autocomplete="off"></v-text-field>
          </v-col>
         


          <v-col class="pb-1" cols="12" md="2" lg="1"> ประเภท : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-radio-group v-model="search.stockType" row>
              <v-radio label="ทั้งหมด" :value="0"></v-radio>
              <v-radio label="มีสต๊อก" :value="1"></v-radio>
              <v-radio label="ไม่มีสต๊อก" :value="2"></v-radio>
            </v-radio-group>
          </v-col>

        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> SKU :</v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.sku" @change="value => search.sku = Trim_value(value)"
              @keyup.enter=" page_lotStock = 1, offsets_lotStock = 0,load_data()" ref="refaddFCName" outlined dense hide-details placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
              autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-btn :elevation="1" class="pa-2 mt-1" color="primary" dark
              @click=" page_lotStock = 1, offsets_lotStock = 0, load_data()">
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
            </v-btn>
            <vue-excel-xlsx id="GetExcel" v-show="false" :data="datasetExcel" :columns="headerExcel"
              :file-name="'ล็อตสินค้า'" :sheet-name="'ล็อตสินค้า'">
            </vue-excel-xlsx>

          </v-col>

        </v-row>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> Barcode :</v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field :value="search.barcode" @change="value => search.barcode = Trim_value(value)"
              @keyup.enter=" page_lotStock = 1, offsets_lotStock = 0,load_data()" ref="refaddFCName" outlined dense hide-details placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
              autocomplete="off"></v-text-field>
          </v-col>
         
        </v-row>
       
      </v-card-text>
    </v-card>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0 py-1" justify="space-between" align="center">
      <v-col cols="8" class="pa-0 ">
        <table>
          <td>
            <span class="head-doc"> สินค้า </span> ทั้งหมด {{ formatMoney(lotStock_sum) }} แถว
          </td>
        </table>
      </v-col>
      <v-col cols="2" class="pa-0  hidden-xs-only  " align="right">
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-data-table :headers="header" :items="lot_stock_list" item-key="id" class="elevation-0 packhai-border-table"
      :mobile-breakpoint="0" :items-per-page="10000000" hide-default-footer>
      <template v-for="(header, index) in header" v-slot:[`header.${header.value}`]="{ header }">
        <v-tooltip :key="index" v-slot:activator="{ on }">
          <span>{{ header.text }}</span>
          <span
            v-if="header.value == 'no' ||header.value == 'quantity' || header.value == 'quantityLeft' || header.value == 'rackNo' || header.value == 'createdDatetime' || header.value == 'dayCount' || header.value == 'manufactureDate' || header.value == 'expirationDate' || header.value == 'dayToExpire'">
            <v-icon v-if="header.icon == 0" @click="OrderByColumn(index)" size="16" class="icon-white">
              mdi-arrow-up
            </v-icon>
            <v-icon v-else-if="header.icon == 1" @click="OrderByColumn(index)" size="16">
              mdi-arrow-up
            </v-icon>
            <v-icon v-else-if="header.icon == 2" @click="OrderByColumn(index)" size="16">
              mdi-arrow-down
            </v-icon>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>

      <template v-slot:item.no="{ item }">
        {{ formatMoney(item.no) }}
      </template>
      <template v-slot:item.photoLink="{ item }">
        <img v-if="item.photoLink != null" :src="item.photoLink" @click="OpenPhoto(item.photoLink)"
          class="picsize mt-1">
        <img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG"
          class="picsize mt-1">
      </template>
      <template v-slot:item.billNumber="{ item }">
        <a @click="detail_IE(item.importExportMasterId)">{{ GenIE(item.importExportMasterId) }}</a>
      </template>
      <template v-slot:item.name="{ item }">
        <span class="color-gray-13">
          <span class="fn-13" style="font-weight: bold; text-decoration: underline"> <a @click="gotoStock_InFo(item)">{{
            item.name }}</a></span>
          <v-row>
            <v-col>
              <span v-if="item.productCode != null && item.productCode != ''"> <span style="color: gray;">รหัสสินค้า :</span> {{ item.productCode
                }}</span>
              <span
                v-if="item.productCode != null && item.productCode != '' && (item.prop1Description || item.prop2Description)">
                ,</span>
              <span v-if="item.prop1Description || item.prop2Description"> {{ !item.prop1Description &&
                !item.prop2Description ? "" : "ลักษณะ" + " : " }} {{ item.prop1Description }} {{ item.prop2Description
                }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-if="item.barcode" >
                <span style="color: gray;">barcode :</span>  {{ item.barcode }}
              </span>
              <span v-if="item.sku" >
                <span v-if="item.barcode" style="color: gray;">, SKU :</span>
                <span v-else style="color: gray;">SKU :</span> {{ item.sku }}
              </span>
             
            </v-col>
          </v-row>
        </span>
      </template>
      <template v-slot:item.quantity="{ item }">
        {{ formatMoney(  item.quantity) }}
      </template>
      <template v-slot:item.quantityLeft="{ item }">
        {{  formatMoney( item.quantityLeft) }}
      </template>
      <template v-slot:item.dayToExpire="{ item }">
        <span v-if="item.dayToExpire<0" style="color: red;">
          {{  formatMoney( item.dayToExpire) }}
        </span>
        <span v-else >
          {{  formatMoney( item.dayToExpire) }}
        </span>
       
      </template>
      <template v-slot:item.dayCount="{ item }"> 
        <span v-if="item.dayCount>0"  >
 
          {{  formatMoney( item.dayCount) }}
        </span>
      </template>

      <template v-slot:item.manufactureDate="{ item }">
        {{ set_format_date_time(item.manufactureDate) }}
      </template>

      <template v-slot:item.expirationDate="{ item }">
        {{ set_format_date_time(item.expirationDate) }}
      </template>

      <template v-slot:item.createdDatetime="{ item }">
        {{ formatDatetime(item.createdDatetime) }}
      </template>
    </v-data-table>

    <!-- เปลี่ยนหน้า -->

   


    <v-row v-if="pageLength_lotStock > 1 && lot_stock_list.length > 0" class="text-center pa-2 ma-0" >
      <v-col cols="12" sm="2" lg="1"> </v-col>
      <v-col cols="12" sm="8" lg="10" @click="changePage_lotStock()">

        <v-pagination v-model="page_lotStock" :length="pageLength_lotStock" :total-visible="7"
          color="primary"></v-pagination>
      </v-col>
      <v-col cols="12" sm="2" lg="1" class="text-right hidden-xs-only">
        <v-select v-model="limits_lotStock" label="จำนวนแสดง" :items="[10, 20, 50, 100, 500, 1000]" @change="LimitChange()"
          outlined></v-select>
      </v-col>
    </v-row>
    <div class="v-card-bottom-30" />
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { utils, writeFileXLSX } from 'xlsx';
import Loading from '@/website/components/Loading'
import { stockService_dotnet, shopService_dotnet,branchService_dotnet } from '@/website/global'
import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
import { formatMoney, GenIE, Trim_value, formatDate, getLocation } from '@/website/global_function'
export default {
  components: {
    Loading
  },
  data: () => ({
    header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
    // page
    BranchID:parseInt(localStorage.getItem('Branch_BranchID')),
    page_loading: true,
    loading: false,
    progress_loading: false,

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: '',

    // ค้นหา
    search: {
      shopList: [{ id: 0, name: "--ทั้งหมด--" }],
      shop: 0,
      barcode:null,
      "lotName": null,
      "name": null,
      "productCode": null,
      "sku": null,
      "quantityCriteriaType": 0,
      "quantityType": [{ "id": 0, "name": "-- ยังไม่เลือก --" }, { "id": 1, "name": "เท่ากับ" }, { "id": 3, "name": "น้อยกว่าหรือเท่ากับ" }, { "id": 2, "name": "มากกว่าหรือเท่ากับ" },],
      "quantity": null,
      "stockType": 1,
      "expire_date": null,
      "mfg_date": null,
      orderby:'id',
      isAscending:false,
    },

    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [[
      "No",
      "ร้านค้า",
      "คลัง",
      "เลขที่นำเข้า",
      "LotName",
      "ชื่อสินค้า",
      "ลักษณะ1",
      "คำอธิบายลักษณะ1",
      "ลักษณะ2",
      "คำอธิบายลักษณะ2",
      "รหัสสินค้า",
      "SKU",
      "Barcode", 
      "RackNo",
      "จำนวนรับเข้า",
      "จำนวนคงเหลือ", 
      "วันที่นำเข้าคลัง",
      "จำนวนวันในคลัง",
      "วันที่ผลิต",
      "วันหมดอายุ",
      "หมดอายุในอีกกี่วัน",
    ]],
   
    excelStyles: [
      {
        id: 'dateType',
        dataType: 'DateTime',
      },
    ],
    warehouseList_filter: [],
    warehouse_selected:null,
    location_list: [],
    location_selected: null,
    isLocationPass: false,
    isButtonUpdatePressed: false,
    // แสดงตาราง
    lotStock_sum: 0,
    lot_stock_list: [],
    header: [
      { text: 'แก้ไข', align: 'center', sortable: false, value: 'edit', icon: 0, width: '60px' },
      { text: 'No', align: 'center', sortable: false, value: 'no', icon: 0, width: '70px' },
      { text: 'เลขบิล', align: 'center', sortable: false, value: 'billNumber', icon: 0, width: '80px' },
      { text: 'รูป', align: 'left', sortable: false, value: 'photoLink', icon: 0, width: '100px' },
      { text: 'รายละเอียดสินค้า', align: 'left', sortable: false, value: 'name', icon: 0,width: '300px'  },
      { text: 'LotName', align: 'left', sortable: false, value: 'lotName', icon: 0 },
      { text: 'จำนวนรับเข้า', align: 'center', sortable: false, value: 'quantity', icon: 0, width: '140px' },
      { text: 'จำนวนคงเหลือ', align: 'center', sortable: false, value: 'quantityLeft', icon: 0, width: '150px' },
      { text: 'RackNo', align: 'center', sortable: false, value: 'rackNo', icon: 0,width: '120px'  },
      { text: 'วันที่รับเข้า', align: 'center', sortable: false, value: 'createdDatetime', icon: 0, width: '130px' },
      { text: 'จำนวนวันในคลัง', align: 'center', sortable: false, value: 'dayCount', icon: 0, width: '160px' },
      { text: 'วันที่ผลิต', align: 'center', sortable: false, value: 'manufactureDate', icon: 0, width: '130px' },
      { text: 'วันหมดอายุ', align: 'center', sortable: false, value: 'expirationDate', icon: 0, width: '130px' },
      { text: 'หมดอายุในกี่วัน', align: 'center', sortable: false, value: 'dayToExpire', icon: 0, width: '160px' },
      { text: 'คลัง', align: 'center', sortable: false, value: 'warehouseName', icon: 0, width: '200px' },
      { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName', icon: 0 ,width: '200px'}, 
     
    ],


    // paginations location
    isPage_lotStock: 0,
    page_lotStock: 1,
    page_tmp_lotStock: null,
    limits_lotStock:10,
    offsets_lotStock: 0,
    pageLength_lotStock: null,


    //  edit item
    editedItem_menuDate: { menuStartDate: false, menuEndtDate: false, dateStart: null, dateEnd: null },
    editedIndex: [],
    dialog: false,
    editedItem: {},
    // บังคับกรอก
    rules: {
      locationRackno: [val => (val || '').length > 0],
      quantityMove: [val => (val || '' || '0').length > 0],
      newLocation: [val => val > 0],
    },

  }),
  computed: {
    showHeaders() {
      return this.headers.filter(s => this.headers.includes(s));
    },
    present_wait() {
      let num = this.count_print_data + this.count_print_data_no_barcode;
      return parseInt(num);
    },


    computed_search_date_mfg() {
      return this.formatDates(this.search.mfg_date)
    },

    computed_search_expire_date() {
      return this.formatDates(this.search.expire_date)
    },



  },
  watch: {
  },
  async created() {
    document.title = this.$router.history.current.meta.title
    this.permission = JSON.parse(localStorage.getItem('permission'))
    this.GetWarehouse();
    this.GetShop();
    this.load_data()
    this.header[1].icon = 1;
    this.page_loading = false


  },
  methods: {
    async GetShop() {
      let response = await axios.post(shopService_dotnet + 'Shop/get-shop-list', {
        "branchId":this.BranchID,
      }, { headers: this.header_token })
      var shopList = response.data.result
      if (response.status == 200 && shopList.length > 0) {
        for (var i in shopList) {
          this.search.shopList.push({
            id: shopList[i].shop.id,
            name: shopList[i].shop.name,
          })
        }
      }
    },

    formatDates(date) {
      if (date == null) {
        return null
      } else {
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      }
    },
    async GetWarehouse() {
      let response = await axios.get(
        branchService_dotnet + "Branch/get-warehouse-list?BranchID=" + this.BranchID,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.search.warehouseIDList = [];
        this.warehouseList_filter = [];
        if (response.data.warehouseList.length > 0) {
          if (response.data.warehouseList.length > 1) {
            this.warehouseList_filter = [{ id: 0, name: "--ทั้งหมด--" }];
          }
          for (var i in response.data.warehouseList) {
            this.search.warehouseIDList.push(response.data.warehouseList[i].id);
            this.warehouseList_filter.push({
              id: response.data.warehouseList[i].id,
              name: response.data.warehouseList[i].name,
            });
          }
        }
        this.warehouse_selected = this.warehouseList_filter[0];
      }
    },
    async OrderByColumn(index) {

      this.loading = true
      this.search.orderby = this.header[index].value
      this.search.isAscending = this.header[index].icon == 0 ? false : (this.header[index].icon == 1 ? true : false)
      
      if(this.search.orderby=="dayCount"){
        this.search.orderby="createdDatetime";
        this.search.isAscending = ! this.search.isAscending;
      }
      if(this.search.orderby=="dayToExpire"){
        this.search.orderby="expirationDate";
      
      }
      if(this.search.orderby=="no"){
        this.search.orderby="id";
      }

      for (var i in this.header) {
        if (!(parseInt(index) == parseInt(i))) {
          this.header[i].icon = 0
        }
      }
      this.header[index].icon = this.header[index].icon == 0 ? 1 : (this.header[index].icon == 1 ? 2 : 1)
      await this.load_data( )
      this.loading = false
    },
    async load_data() {
      this.loading = true

      if (this.search.quantity == '') {
        this.search.quantity = null
      }
      var warehouselist = [];
      console.log('this.warehouse_selected');
      console.log(this.warehouse_selected);
      
      if(this.warehouse_selected!=null){
        if(this.warehouse_selected.id==0){
          for (let index = 1; index < this.warehouseList_filter.length; index++) {
            warehouselist.push(this.warehouseList_filter[index].id);
          }
        }else{
          warehouselist.push(this.warehouse_selected.id);
        }
      }console.log(warehouselist);
      
      try {
        let response = await axios.post(stockService_dotnet + 'Stock/search-stockshop-item-list', {
          "dateFrom": null,
          "dateTo": null,
          "lotName": this.search.lotName,
          "branchID": this.BranchID,
          "warehouseIDList": warehouselist,
          "shopID": this.search.shop == 0 ? 0 : this.search.shop,
          "name": this.search.name,
          "sku": this.search.sku,
          "barcode":this.search.barcode,
          "exp_date": !this.search.expire_date ? null : this.search.expire_date + "T00:00:00.000Z",
          "mfg_date": !this.search.mfg_date ? null : this.search.mfg_date + "T00:00:00.000Z",
          "quantityCriteriaType": this.search.quantityCriteriaType,
          "quantity": this.search.quantity,
          "stockType": this.search.stockType,
          "orderby":this.search.orderby,
          "isAscending":this.search.isAscending,
          "skip": this.offsets_lotStock,
          "take": this.limits_lotStock,
          "IsNeedResultCount":true
        }, { headers: this.header_token })

        this.lot_stock_list = []
        this.lotStock_sum = 0
        if (response.data.dataList != null) {
          this.lotStock_sum = response.data.resultCount
          this.lot_stock_list = JSON.parse(JSON.stringify(response.data.dataList))
        }
        this.getpagelength_lotStock(response.data.resultCount)
        this.loading = false
      }
      catch (e) {
        AlertError(e.message)
        this.loading = false
      }

    },

    detail_IE(id) {
      window.open("transfer-list/update?id=" + btoa(id));
    },

    set_format_date_time(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10)
        const date_split = date_tmp.split('-');
        var time_tmp = val.substring(11, 19)
        var set_dattime = date_split[2] + '-' + date_split[1] + '-' + date_split[0] + ' ' //+ time_tmp
        return set_dattime
      } else {
        return ""
      }
    },
    set_format_date_time_for_excel(val) {
      if (val != null) {

        var date_tmp = val.substring(0, 10)
        const date_split = date_tmp.split('-');
        var time_tmp = val.substring(11, 19)
        var set_dattime = date_split[0] + '-' + date_split[1] + '-' + date_split[2]  //+ time_tmp
        return set_dattime
      } else {
        return null
      }
    },
    formatDatetime(val){
      if (val != null) {

      var date_tmp = val.substring(0, 10)
      const date_split = date_tmp.split('-');
      var time_tmp = val.substring(11, 19)
      var set_dattime = date_split[2] + '-' + date_split[1] + '-' + date_split[0]  +" " + time_tmp;
        return set_dattime
      } else {
        return null
      }
    },
    formatDatetimeForExcel(val){
      if (val != null) {

      var date_tmp = val.substring(0, 10)
      const date_split = date_tmp.split('-');
      var time_tmp = val.substring(11, 19)
      var set_dattime = date_split[0] + '-' + date_split[1] + '-' + date_split[2]  +" " + time_tmp;
        return set_dattime
      } else {
        return null
      }
    },
    getpagelength_lotStock(total_count) {
      var total = 0
      if (total_count != 0) {
        total = total_count
      } else {
        total = 1
      }

      total = parseInt(total)
      this.pageLength_lotStock = Math.ceil(total / this.limits_lotStock)
      this.isPage_lotStock = 0
      this.page_tmp_lotStock = this.page_lotStock
    },
    async LimitChange(){
      this.page_lotStock=1;
      await this.load_data();
    },
    async changePage_lotStock() {
      this.loading = true
      this.offsets_lotStock = (this.page_lotStock * this.limits_lotStock) - this.limits_lotStock

      if (this.page_lotStock > 1 && this.page_lotStock < this.pageLength_lotStock) {
        if (this.page_tmp_lotStock != this.page_lotStock) {
          await this.load_data()
          this.page_tmp_lotStock = this.page_lotStock
        }
      } else if (this.page_lotStock == 1, this.pageLength_lotStock) {
        if (this.page_tmp_lotStock != this.page_lotStock) {
          await this.load_data()
          this.page_tmp_lotStock = this.page_lotStock
        }
      }
      this.loading = false
    },

    gen_num_data_table(index, sum_data, offsets) {
      if (index != null && this.loading == false) {
        var start = null
        start = sum_data - offsets
        return formatMoney(start - index)
      } else {
        return ""
      }
    },
    GetSelectedRowForExcel(item){
      var returnObject= {
        "no":item.no,
        "ร้านค้า":item.shopName,
        "คลัง":item.warehouseName,
        "เลขที่นำเข้า":GenIE(item.importExportMasterId),
        "LotName":item.lotName,
        "ชื่อสินค้า":item.name,
        "ลักษณะ1":item.prop1,
        "คำอธิบายลักษณะ1":item.prop1Description,
        "ลักษณะ2":item.prop2,
        "คำอธิบายลักษณะ2":item.prop2Description,
        "รหัสสินค้า":item.productCode,
        "SKU":item.productCode,
        "Barcode":item.barcode, 
        "RackNo":item.rackNo,
        "จำนวนรับเข้า":item.quantity,
        "จำนวนคงเหลือ":item.quantityLeft, 
        "วันที่นำเข้าคลัง":this.formatDatetimeForExcel(item.createdDatetime), 
        "จำนวนวันในคลัง":item.dayCount, 
        "วันที่ผลิต":this.set_format_date_time_for_excel(item.manufactureDate), 
        "วันหมดอายุ":this.set_format_date_time_for_excel(item.expirationDate), 
        "หมดอายุในอีกกี่วัน":item.dayToExpire, 
      };
      return  returnObject;

    },
    async ExportExcel(search) {
      this.datasetExcel = []
      this.loadingExcel = 0
      Swal.fire({
        position: "top",
        icon: 'warning',
        text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          this.loadingExcel = 1;
          var limits = 100
          var offsets = 0
      

          var data = await this.GetExportExcelProduct(limits, offsets,true)
          for (var j in data.dataList) {
            this.datasetExcel.push(this.GetSelectedRowForExcel(data.dataList[j]))
          }
          offsets=offsets+limits;
          let index = this.datasetExcel[0].no;
    
          
          var isDesc = true;
          if (index == 1) {
            isDesc = false;
            index = index + limits;
          } else {
            index = index - limits;
          }

       
          var total = data.resultCount;
          var round = parseInt(total / limits)

          if (limits * round < total) {
            round = round + 1
          }

          var current = 0
          for (var i =2; i <= round; i++) {
            data = await this.GetExportExcelProduct(limits, offsets,false)
            for (  j in data.dataList) {
             

              if (isDesc) {
                data.dataList[j].no = index;
              
                
                index--;
              } else {
                data.dataList[j].no = index;
                index++;
              }

              this.datasetExcel.push(this.GetSelectedRowForExcel(data.dataList[j]))
            }
            this.loadingExcel = parseInt(parseFloat(i) / parseFloat(round) * parseFloat(100))
            offsets += limits
          }

     

          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100
          const ws1 = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws1, this.headerExcel);
          utils.sheet_add_json(ws1, this.datasetExcel, { origin: 'A2', skipHeader: true });

          const wb = utils.book_new();
          utils.book_append_sheet(wb, ws1, "sheet1"); 

          const d = new Date();
          let dateExported = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDate().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
          writeFileXLSX(wb, 'รายงานข้อมูลล๊อตสินค้า_' + dateExported + ".xlsx");
        }
      })
    },
    async GetExportExcelProduct(limits, offsets, IsNeedResultCount) {
      if (this.search.quantity == '') {
        this.search.quantity = null
      }

      var warehouselist = [];
      if(this.warehouse_selected!=null){
        if(this.warehouse_selected.id==0){
          for (let index = 1; index < this.warehouseList_filter.length; index++) {
            warehouselist.push(this.warehouseList_filter[index].id);
          }
        }else{
          warehouselist.push(this.warehouse_selected.id);
        }
      }


      try {
        let response = await axios.post(stockService_dotnet + 'Stock/search-stockshop-item-list', {
          "dateFrom": null,
          "dateTo": null,
          "lotName": this.search.lotName,
          "branchID":  this.BranchID, 
          "warehouseIDList": warehouselist,
          "shopID": this.search.shop == 0 ? 0 : this.search.shop,
          "name": this.search.name,
          "sku": this.search.sku,
          "barcode":this.search.barcode,
          "exp_date": !this.search.expire_date ? null : this.search.expire_date + "T00:00:00.000Z",
          "mfg_date": !this.search.mfg_date ? null : this.search.mfg_date + "T00:00:00.000Z",
          "quantityCriteriaType": this.search.quantityCriteriaType,
          "quantity": this.search.quantity,
          "stockType": this.search.stockType,
          "orderby":this.search.orderby,
          "isAscending":this.search.isAscending,
          "skip": offsets,
          "take": limits, 
          "isNeedResultCount":IsNeedResultCount,

        }, { headers: this.header_token });
        return response.data
      }
      catch (e) {
        AlertError(e.message)
        this.loading = false
      }
    },

    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo
      this.ShowPhoto = true
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },

    gotoStock_InFo(item) {
      window.open("stock-info?id=" + item.stockShopId);
    },

    async editItem(item) {
      this.loading = true;
      this.location_list = await this.getLocation();
      this.editedIndex = this.lot_stock_list.indexOf(item)
      this.editedItem = Object.assign({}, item)

      if (this.editedItem.expirationDate != null) {
        this.editedItem.expirationDate = formatDate(item.expirationDate.substring(0, 10))
      }

      if (this.editedItem.manufactureDate != null) {
        this.editedItem.manufactureDate = formatDate(item.manufactureDate.substring(0, 10))
      }

      if (this.location_list.length > 0) {
        let locationfound = null;
        let findByText = false;
        if (this.editedItem.locationID != null) {
          locationfound = this.location_list.find(item => item.locationID == this.editedItem.locationID);
          if (locationfound != null) {
            this.location_selected = locationfound;
          } else {
            findByText = true;
          }
        } else {
          findByText = true;
        }

        if (findByText && this.editedItem.rackNo) {
          locationfound = this.location_list.find(item => item.locationShortName == this.editedItem.rackNo);
          if (locationfound != null) {
            this.location_selected = locationfound;
            this.editedItem.locationID = locationfound.locationID;
          } else {
            this.editedItem.locationID = 0;
            this.location_selected = {
              locationID: 0,
              locationName: '',
              locationShortName: this.editedItem.rackNo
            }
          }
        } else if (findByText && !this.editedItem.rackNo) {
          this.location_selected = null;
        }
      }
      this.isLocationPass = true;
      this.isButtonUpdatePressed = false;

      this.dialog = true
      this.loading = false;
    },
    async SaveEditStockShopItem() {
      this.isButtonUpdatePressed = true;
      if (this.location_list.length > 0) {
        if (this.isLocationPass) {
          this.SaveEditStockShopItem2();
        }
      } else {
        this.SaveEditStockShopItem2();
      }
    },
    async SaveEditStockShopItem2() {
      await Object.assign(this.lot_stock_list[this.editedIndex], this.editedItem)
      this.loading=true;
      let bodyItemList = this.lot_stock_list[this.editedIndex];
    
      var body = {
        "branchID": this.BranchID,
        "stockShopId": parseInt(bodyItemList.stockShopId),
        "stockShopItemId": parseInt(bodyItemList.stockShopItemId),
        "manufactureDate": !bodyItemList.manufactureDate ? null : bodyItemList.manufactureDate = this.parseDate(bodyItemList.manufactureDate),
        "expirationDate": !bodyItemList.expirationDate ? null : bodyItemList.expirationDate = this.parseDate(bodyItemList.expirationDate),
        "rackNo": bodyItemList.rackNo,
        "lotName": bodyItemList.lotName,
        "remark": bodyItemList.remark,
        "costPrice": bodyItemList.costPrice,
        "locationID": bodyItemList.locationID,
        "stockSequence": parseInt(bodyItemList.stockSequence) > 0 ? parseInt(bodyItemList.stockSequence) : null
      }
      var response = await axios.post(stockService_dotnet + 'Stock/update-stockshop-item-list', body, { headers: this.header_token })
      this.loading = false;
      if (response.data.status) {
        Swal.fire({
          toast: true,
          position: 'top',
          timer: 1500,
          icon: 'success',
          text: 'บันทึกสำเร็จ',
        });
        this.dialog = false 
        this.editedItem = {}
        this.load_data();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'แก้ไขข้อมูลไม่สำเร็จ',
          text: response.data.description,
        })
      }
     
    },


    // formatDate (date) {
    //   if (!date) return null
    //   const [year, month, day] = date.split('-')
    //   return `${day}/${month}/${year}`
    // },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    cb_locationKeyPressed(event) {
      this.isLocationPass = false;
    },
    location_selected_changed(val) {


      if (typeof val === 'object' && val != null) {
        this.location_selected = val;
        this.editedItem.rackNo = this.location_selected.locationShortName;
        this.editedItem.locationID = this.location_selected.locationID;
        this.isLocationPass = true;
      }
      else {
        if (val) {
          this.location_selected = {
            locationID: 0,
            locationName: val,
            locationShortName: val
          }
          this.editedItem.rackNo = this.location_selected.locationShortName;
          this.editedItem.locationID = this.location_selected.locationID;
        } else {
          this.location_selected = null;
          this.editedItem.rackNo = null;
          this.editedItem.locationID = null;

        }
        this.isLocationPass = true;
        if (this.isButtonUpdatePressed) {
          this.SaveEditStockShopItem2();
        }
      }
    },
    



    allowedDates: val => val <= new Date().toISOString().substr(0, 10),
    formatMoney,
    Trim_value,
    AlertSuccess,
    AlertWarning,
    AlertError,
    formatDate,
    GenIE,
    getLocation
  }
}
</script>

<style scoped>
>>>.v-select__slot {
  height: 40px;
}

.cut-text {
  text-decoration: none;
  text-overflow: ellipsis;
  /* เพิ่ม ... จุดจุดจุดท้ายสุด */
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
  /* กำหนดความกว้าง */
}

.sort-bar {
  font-weight: 400;
  background: rgba(0, 0, 0, .03);
  padding: 0.8125rem 1.25rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.w-200P {
  width: 200px;
}

.p-15 {
  padding: 0px 0px 0px 15px;
}

.w-13r {
  width: 13rem;
}

>>>.v-dialog {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
